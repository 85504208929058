import type { CasaoneBaseQueryArgs } from "api/types";
import { IaziAddressValidationRequestBody } from "./types";

const query = (
  body: IaziAddressValidationRequestBody
): CasaoneBaseQueryArgs => ({
  url: "api/v1/iazi-address-validation",
  method: "POST",
  data: { ...body },
});

export default query;
