import { useEffect, useCallback } from "react";
import { EventTypesShape, getEventTypeList } from "redux/eventTypes";
import { useSelector, useDispatch } from "react-redux";
import { type AppDispatch, RootState } from "redux/store";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux/hooks";
import {
  selectContactLang,
  selectContractMainLang,
} from "redux/auth/selectors";

const useEventTypes = () => {
  const eventTypes = useSelector((state: RootState) => state.eventTypes);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const userLang = useAppSelector(selectContactLang);
  const mainContractLang = useAppSelector(selectContractMainLang);

  useEffect(() => {
    if (eventTypes.lastFetched === 0) {
      dispatch(getEventTypeList());
    }
  }, [eventTypes.lastFetched, dispatch]);

  type GetSingleItemArgs = {
    value: EventTypesShape["eventTypesList"][number]["value"];
    context?: string;
  };
  const getList = useCallback(
    ({ context }: { context?: GetSingleItemArgs["context"] }) => {
      if (!context) {
        return eventTypes.eventTypesList;
      }

      return eventTypes.eventTypesList.filter((elem) => {
        if (!elem.context) {
          console.warn("NO CONTEXT VALUE FOR", elem); // eslint-disable-line no-console
        }
        return elem.context?.includes(context);
      });
    },
    [eventTypes.eventTypesList]
  );

  const getContentsLabelHelper = useCallback(
    (contents: { lang: string; label: string }[] = []) => {
      const findLang = (lang: string) => {
        return contents.find((elem) => elem.lang === lang);
      };
      // check for userLang first and then the company mainLang
      const currLangContent =
        findLang(userLang) || findLang(mainContractLang) || findLang("de");

      // fallback to first language inside contents
      return currLangContent?.label || contents[0]?.label || "";
    },
    [mainContractLang, userLang]
  );

  const getSingleItem = useCallback(
    ({ value, context }: GetSingleItemArgs) => {
      const eventTypesListSingleItem = getList({ context }).find((elem) => {
        if (elem.value === value) {
          return true;
        }
        return false;
      });
      if (eventTypesListSingleItem) {
        return {
          ...eventTypesListSingleItem,
          label: getContentsLabelHelper(eventTypesListSingleItem.contents),
        };
      }
    },
    [getList, getContentsLabelHelper]
  );

  const getSingleItemLabel = useCallback(
    (args: GetSingleItemArgs) => {
      const singleItem = getSingleItem(args);
      return getContentsLabelHelper(singleItem?.contents);
    },
    [getSingleItem, getContentsLabelHelper]
  );

  const groupedEventTypesListHelper = useCallback(
    (inputArray: EventTypesShape["eventTypesList"]) => {
      const reducer = (
        result: {
          [key: string]: {
            label: string;
            options: Omit<
              EventTypesShape["eventTypesList"][number] & { label: string },
              "group" | "options"
            >[];
          };
        },
        {
          baseType,
          eventType,
          contents,
          context,
          group,
          icon,
          iconSolid,
          iconRegular,
          defaultDuration,
          value,
        }: EventTypesShape["eventTypesList"][number]
      ) => {
        const theGroup = group
          ? group
          : {
              name: "other",
              contents: [
                {
                  lang: userLang,
                  label: t("Other"),
                },
              ],
            };
        if (!result[theGroup.name]) {
          const groupLabel = getContentsLabelHelper(theGroup.contents);
          result[theGroup.name] = {
            label: groupLabel,
            options: [],
          };
        }

        // get currLang label
        const label = getContentsLabelHelper(contents);

        result[theGroup.name].options.push({
          label: label,
          baseType,
          eventType,
          contents,
          context,
          icon,
          iconSolid,
          iconRegular,
          defaultDuration,
          value,
        });

        return result;
      };
      const reducedInput = inputArray.reduce(reducer, {});
      // sort by alphabet and put others at last position
      const groupsArray = Object.entries(reducedInput).sort(
        ([group1Name, group1], [group2Name, group2]) => {
          if (group1Name === "other") {
            return 1;
          }
          if (group1.label > group2.label) {
            return 1;
          }
          if (group1.label < group2.label) {
            return -1;
          }
          return 0;
        }
      );
      // flatten down to group values only
      return groupsArray.map((gr) => gr[1]);
    },
    [getContentsLabelHelper, userLang, t]
  );

  const getGroupedEventTypesList = useCallback(
    ({ context }: { context?: GetSingleItemArgs["context"] } = {}) => {
      return groupedEventTypesListHelper(getList({ context }));
    },
    [getList, groupedEventTypesListHelper]
  );

  return {
    getList: getList,
    getSingleItem: getSingleItem,
    getSingleItemLabel: getSingleItemLabel,
    getGroupedEventTypesList: getGroupedEventTypesList,
  };
};

export default useEventTypes;
