import type { CasaoneBaseQueryArgs } from "api/types";
import { IaziMunicipalityInfoRequestBody } from "./types";

const query = (
  body: IaziMunicipalityInfoRequestBody
): CasaoneBaseQueryArgs => ({
  url: "api/v1/iazi-municipality-info",
  method: "POST",
  data: { ...body },
});

export default query;
