import moment, { type LocaleSpecifier, type MomentInput } from "moment";
import "moment/locale/de-ch";
import "moment/locale/fr-ch";
import "moment/locale/it-ch";
import "moment/locale/en-gb";
import store from "redux/store";
import { DEFAULT_USER_LANG } from "redux/auth/selectors";

const LOCALE_MAP: Record<string, LocaleSpecifier> = {
  de: "de-ch",
  fr: "fr-ch",
  it: "it-ch",
  en: "en-gb",
};
const DEFAULT_LOCALE = "de-ch";

const getLocale = (lang: string): LocaleSpecifier => {
  return LOCALE_MAP[lang] || DEFAULT_LOCALE;
};

const csMoment = (date?: MomentInput) => {
  const state = store.getState();
  const lang =
    state.auth.profile.data.casaoneUser?.contact?.lang || DEFAULT_USER_LANG;

  const dateObject = moment(date);
  dateObject.locale(getLocale(lang));

  return dateObject;
};

export default csMoment;
