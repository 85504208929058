import { useFilterFormContactLoader } from "./useFilterFormContactLoader";
import { useTranslation } from "react-i18next";
import csMoment from "utilities/csMoment";
import { getContactLabel } from "./helpers/filterFormOptionsRendererHelpers";

const useFilterFormOptionsRenderer = () => {
  const { t } = useTranslation();
  const { loadContactDetails } = useFilterFormContactLoader();

  const renderContact = async (
    idList: string[],
    byEmail: boolean
  ): Promise<string> => {
    const contactsForPreview = await loadContactDetails({ idList, byEmail });

    return idList
      .map(
        (id) =>
          getContactLabel({
            contact: contactsForPreview[id],
            // we don't want to use contact email as fallback if preset is already using email (username) as id
            useEmailFallback: !byEmail,
          }) || id
      )
      .join(", ");
  };

  const renderStatus = (values: string[]): string => {
    return (
      values
        ?.map((singleValue) => {
          switch (singleValue) {
            case "fresh":
              return t("Pending");
            case "done":
              return t("Done");
            default:
              return singleValue;
          }
        })
        .join(", ") || ""
    );
  };

  const renderDateRange = (terms: string[]): string => {
    return terms
      .map((term) => (term ? csMoment(term).format("DD.MM.YYYY") : "∞"))
      .join(" - ");
  };

  const renderMessageStatus = (value: string[]): string => {
    if (!value) {
      return "";
    }
    const rendered = value.map((singleValue) => {
      switch (singleValue) {
        case "fresh":
          return t("Message is Fresh");
        case "seen":
          return t("Message has been Seen");
        case "done":
          return t("Message is Done");
        default:
          return singleValue;
      }
    });
    return rendered.join(", ");
  };

  const renderCount = (terms: string[]): string => {
    const count = terms.length;

    return count > 0 ? `${count}` : "";
  };

  return {
    renderContactById: async (contactIds: string[]) =>
      renderContact(contactIds, false),
    renderContactByEmail: async (emails: string[]) =>
      renderContact(emails, true),
    renderStatus,
    renderDateRange,
    renderMessageStatus,
    renderCount,
  };
};

export default useFilterFormOptionsRenderer;
