import { CasaoneBaseQueryArgs } from "api/types";
import queryBuilder from "utilities/queryBuilder";
import { GetSharedSettingsListQueryParams } from "./types";

function query({
  query,
}: GetSharedSettingsListQueryParams): CasaoneBaseQueryArgs {
  const builtQuery = queryBuilder.buildToUrl(query.orderBy, query.filter, {
    pagesize: query.pageSize,
    page: query.page,
  });
  return {
    url: `api/v1/casa-message-setting?${builtQuery}`,
    method: "GET",
  };
}

export default query;
