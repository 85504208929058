import { CasaoneBaseQueryArgs } from "api/types";
import { IaziPrevalidationRequestBody } from "./types";

const query = (body: IaziPrevalidationRequestBody): CasaoneBaseQueryArgs => ({
  url: `api/v1/iazi-ai-validation`,
  method: "POST",
  data: { ...body },
  // 422 is the succesfull response for properties that fail validation
  validateStatus: (status) => status === 200 || status === 422,
});

export default query;
