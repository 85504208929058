import type { ContactShape } from "entities/contact/types";

export const getContactLabel = ({
  contact,
  useEmailFallback = true,
}: {
  contact: ContactShape | undefined;
  useEmailFallback?: boolean;
  fallback?: string;
}): string => {
  if (contact) {
    const { displayName, firstName, lastName, email } = contact;
    const label = displayName || `${firstName || ""} ${lastName || ""}`.trim();

    return useEmailFallback ? label || email || "" : label;
  }

  return "";
};
