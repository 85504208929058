import { DatePicker, DateRange, UserLocale } from "./DatePicker/DatePicker";
import {
  faArrowRight,
  faCalendar,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputHTMLAttributes, useRef, useState } from "react";
import Field from "@casasoft/styleguide/components/formElements/Field";
import { useDatePickerDialog } from "./hooks/useDatePickerDialog";
import { twMerge } from "tailwind-merge";
import { getParsedDateValue, isValidDateFormat } from "./DatePicker/helpers";
import { isValidDateString } from "utilities/dateTime";
import FieldContainerInterface from "./FieldContainerInterface";

type OnChangeSingle = (value?: string) => void;
type OnChangeRange = (value?: DateRange | null) => void;

export interface DatePickerFieldElemProps
  extends Omit<
      InputHTMLAttributes<HTMLInputElement>,
      "placeholder" | "prefix" | "onChange" | "onFocus" | "value" | "onBlur"
    >,
    Pick<
      FieldContainerInterface,
      "message" | "onBlur" | "autoFocus" | "label"
    > {
  label?: string;
  value?: string | DateRange;
  id?: string;
  onChange?: OnChangeSingle | OnChangeRange;
  rangeStart?: string;
  rangeEnd?: string;
  locale?: UserLocale;
  disabled?: boolean;
  required?: boolean;
  showTodayButton?: boolean;
  labelEnd?: string;
}

export const DatePickerFieldElem = ({
  label = "",
  onChange,
  value = "",
  id = "",
  rangeStart,
  rangeEnd,
  locale,
  disabled = false,
  required = false,
  showTodayButton = true,
  labelEnd, // add this prop to create range input field
  message,
  onBlur,
  autoFocus,
}: DatePickerFieldElemProps) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isDialogOpen, setIsDialogOpen } = useDatePickerDialog(
    containerRef,
    dialogRef
  );
  const [startValue, setStartValue] = useState<string>("");
  const [endValue, setEndValue] = useState<string>("");

  return (
    <div className="tw-relative date-picker-field-elem tw-w-full">
      <div className="tw-flex tw-w-full tw-relative" ref={containerRef}>
        {!!labelEnd && (
          <>
            <Field
              nobox
              required={required}
              id={id}
              value={
                startValue ||
                getParsedDateValue(
                  "DD.MM.YYYY",
                  String((value as DateRange).from || "")
                )
              }
              disabled={disabled}
              label={label}
              onFocus={() => setIsDialogOpen(true)}
              onBlur={(newDate) => {
                (onChange as OnChangeRange)?.({
                  to: (value as DateRange)?.from,
                  from: isValidDateString(newDate),
                });
                setStartValue(newDate);
              }}
              message={message}
              autoFocus={autoFocus}
            />
            <div className="tw-mb-4 tw-px-2 tw-border-b tw-border-cs-shade-500 tw-flex tw-items-end">
              <span className="tw-pb-1">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="tw-text-cs-shade-400"
                  size="xs"
                />
              </span>
            </div>
          </>
        )}
        <Field
          nobox
          required={required}
          id={id}
          value={
            endValue
              ? endValue
              : getParsedDateValue(
                  "DD.MM.YYYY",
                  labelEnd
                    ? String((value as DateRange).to || "")
                    : (value as string)
                )
          }
          disabled={disabled}
          label={labelEnd || label}
          onFocus={() => setIsDialogOpen(true)}
          onChange={(newDate) => {
            if (!labelEnd && isValidDateFormat(newDate)) {
              (onChange as OnChangeSingle)?.(
                getParsedDateValue("YYYY-MM-DD", newDate || "")
              );
              setEndValue(newDate);
              setIsDialogOpen(false);
            }
          }}
          onBlur={(newDate) => {
            if (labelEnd) {
              (onChange as OnChangeRange)?.({
                from: (value as DateRange)?.from,
                to: isValidDateString(newDate),
              });
            } else {
              (onChange as OnChangeSingle)?.(
                getParsedDateValue("YYYY-MM-DD", newDate || "")
              );
              setEndValue(newDate);
            }
            onBlur?.(getParsedDateValue("YYYY-MM-DD", newDate || ""));
          }}
          suffix={
            <button
              onClick={() => {
                if (labelEnd && onChange) {
                  (onChange as OnChangeRange)?.({
                    from: undefined,
                    to: undefined,
                  });
                  setEndValue("");
                  setStartValue("");
                } else {
                  (onChange as OnChangeSingle)?.(undefined);
                  setEndValue("");
                }
              }}
              aria-label="Remove date value"
              type="button"
            >
              <FontAwesomeIcon
                icon={faTimes}
                className="tw-text-cs-shade-400"
                size="xs"
              />
            </button>
          }
        />
        <button
          onClick={() => {
            !disabled && setIsDialogOpen(!isDialogOpen);
          }}
          aria-label="Open calendar"
          type="button"
          className="tw-mb-4 tw-flex tw-items-end tw-pb-2 tw-outline-0"
        >
          <FontAwesomeIcon icon={faCalendar} />
        </button>
      </div>
      <div
        id="datePickerDialog"
        ref={dialogRef}
        aria-modal
        className={twMerge(
          "tw-border-none tw-p-0 tw-rounded-sm tw-z-[10000] tw-absolute",
          isDialogOpen ? "" : "tw-hidden"
        )}
      >
        <DatePicker
          onChange={(newDate) => {
            if (labelEnd) {
              const { from, to } = newDate as DateRange;
              const isStartDateChange = from === to;
              (onChange as OnChangeRange)?.({
                from,
                to: isStartDateChange ? undefined : to,
              });

              setStartValue(
                getParsedDateValue("DD.MM.YYYY", String(from || ""))
              );
              !isStartDateChange &&
                setEndValue(getParsedDateValue("DD.MM.YYYY", String(to || "")));

              if (!isStartDateChange) {
                setIsDialogOpen(false);
              }
            } else {
              (onChange as OnChangeSingle)?.(
                getParsedDateValue("YYYY-MM-DD", (newDate as string) || "")
              );
              setEndValue(
                getParsedDateValue("DD.MM.YYYY", (newDate as string) || "")
              );
              setIsDialogOpen(false);
              onBlur?.(
                getParsedDateValue("YYYY-MM-DD", (newDate as string) || "")
              );
            }
          }}
          showTodayButton={showTodayButton}
          locale={locale as UserLocale}
          value={value}
          rangeStart={rangeStart}
          rangeEnd={rangeEnd}
          isRangeMode={!!labelEnd}
        />
      </div>
    </div>
  );
};
