import { Alert } from "@casasoft/styleguide/components/helpers-ux";
import { MessagePendingText } from "./MessagePendingText";

export const MessagePendingAlert = () => {
  return (
    <Alert color={"info"}>
      <MessagePendingText />
    </Alert>
  );
};
