import { createQuery } from "api/createEndpoint";
import { MESSAGE_SETTINGS_API_ROOT } from "../constants";
import type { UpdateSingleCompanyMessageSettingsPayload } from "./types";

const query = createQuery<UpdateSingleCompanyMessageSettingsPayload>(
  ({ type, body }) => {
    return {
      url: `${MESSAGE_SETTINGS_API_ROOT}/${type}/company`,
      method: "PATCH",
      data: body,
    };
  }
);

export default query;
