import ReactDOM from "react-dom";
import ChunkBoundary from "components/outside/ChunkBoundary";
import { Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { i18n } from "utilities";
import { Provider } from "react-redux";
import store from "redux/store";
import { Route } from "react-router";
import CasaauthContainer from "components/outside/CasaauthContainer";
import PublicRoutes from "components/outside/PublicRoutes";
import EnvSettingsContainer from "components/miscellaneous/EnvSettingsContainer";
import { history } from "config/history";
import { Location } from "history";
import initHistoryListener from "utilities/history";
import initDatadog from "utilities/datadogService";

initHistoryListener();
initDatadog();

export type CsHistoryChangeEventType = CustomEvent<{
  location: Location<unknown>;
}>;

ReactDOM.render(
  <ChunkBoundary>
    <Router history={history as any}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <Route path="/">
            <PublicRoutes>
              <EnvSettingsContainer>
                <CasaauthContainer />
              </EnvSettingsContainer>
            </PublicRoutes>
          </Route>
        </I18nextProvider>
      </Provider>
    </Router>
  </ChunkBoundary>,
  document.getElementById("root")
);
