import { useEffect, RefObject, useState } from "react";

export const useDatePickerDialog = (
  containerRef: RefObject<HTMLDivElement>,
  dialogRef: RefObject<HTMLDivElement>
) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Handle clicks outside the dialog
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element;
      const isInsideSelectMenu = target.closest(".Select__menu-list") !== null;

      if (
        containerRef.current &&
        !containerRef.current.contains(target as Node) &&
        dialogRef.current &&
        !dialogRef.current.contains(target as Node) &&
        !isInsideSelectMenu
      ) {
        setIsDialogOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [containerRef, dialogRef]);

  return { isDialogOpen, setIsDialogOpen };
};
