import { RegisterOptions } from "react-hook-form";
import { timeFormatRule } from "./form-rules/timeFormatRule";
import { requiredRule } from "./form-rules/requiredRule";

export type FormRule = Omit<
  RegisterOptions,
  "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
>;

export type AvailableGeneralRules =
  | "required"
  | "timeFormat"
  | "minLength"
  | "maxLength";

export interface UseRulesResponse {
  generateRules: (includedRules: AvailableGeneralRules[]) => FormRule;
}

export const useRules = (): UseRulesResponse => {
  const defaultRules: Partial<Record<AvailableGeneralRules, FormRule>> = {
    required: requiredRule(),
    timeFormat: timeFormatRule(),
  };

  const generateRules = (includedRules: AvailableGeneralRules[]): FormRule => {
    return includedRules.reduce<FormRule>((result, rule) => {
      result = { ...result, ...defaultRules[rule] };
      return result;
    }, {});
  };

  return { generateRules };
};
