import { CasaoneBaseQueryArgs } from "api/types";
import { SendMessageRequestPayload } from "./types";

function query(data: SendMessageRequestPayload): CasaoneBaseQueryArgs {
  return {
    url: `api/v1/message/send`,
    method: "POST",
    data,
  };
}

export default query;
