import flatten from "lodash/flatten";

export type OrderByItemType = "field" | "customorderby";

export interface OrderByItem {
  type: OrderByItemType;
  field: string;
  alias?: "address" | "content" | "noAlias" | string;
  direction: "asc" | "desc";
}

// accepted types from the api reference: casaone/api/config/autoload/zf-doctrine-querybuilder.global.php:24
type filterItemTypes =
  | "eq"
  | "neq"
  | "notlike"
  | "like"
  | "leftjoin"
  | "innerjoin"
  | "isnull"
  | "isnotnull"
  | "in"
  | "notin"
  | "search"
  | "match"
  | "special"
  | "lt"
  | "gt"
  | "lte"
  | "gte"
  | "custommin"
  | "distinct";

export type FilterItem =
  | {
      parentAlias?: string;
      where?: "and" | "or";
      type: filterItemTypes;
      field?: string;
      alias?: string;
      value?: string;
      values?: string[];
      condition?: string;
      conditionType?: "with" | "on";
    }
  | {
      where?: "and" | "or";
      type: "orx" | "andx";
      field?: string;
      alias?: string;
      value?: string;
      values?: string[];
      conditions: FilterItem[];
    }
  | {
      where?: "and" | "or";
      type: "between";
      field?: string;
      alias?: string;
      value?: undefined;
      values?: undefined;
      from: number | string;
      to: number | string;
    }
  // custom queries
  | {
      where?: "and" | "or";
      type: "dobbetween";
      field?: string;
      alias?: string;
      value?: undefined;
      values?: undefined;
      from: number | string;
      to: number | string;
    }
  | {
      type: "match";
      id: string;
      where?: "and" | "or";
      filterListKey?: string;
      field: undefined;
      alias: undefined;
      value: undefined;
      values: undefined;
    };

export interface Params {
  [key: string]: string | number | boolean;
}

export type FieldsType = ArrayLike<string | FieldsType[]>;

export const getFieldsAsString = ({
  fields,
}: {
  fields?: FieldsType;
}): string => (fields && fields.length ? flatten(fields).join(",") : "");

const build = (
  orderBy: OrderByItem[] | null,
  filter: FilterItem[] | null,
  params?: Params,
  fields?: string[]
) => {
  const query: Params = {};

  if (fields) {
    query.fields = getFieldsAsString({ fields });
  }

  if (orderBy) {
    orderBy.forEach((orderItem, orderKey) => {
      Object.entries(orderItem).forEach((orderItemProp) => {
        query[`order-by[${orderKey}][${orderItemProp[0]}]`] = orderItemProp[1];
      });
    });
  }
  if (filter) {
    filter.forEach((filterItem, filterKey) => {
      if (filterItem.type === "orx" || filterItem.type === "andx") {
        const addNestedConditions = (fItem: FilterItem, filterBase: string) => {
          query[`${filterBase}[type]`] = fItem.type;
          if (fItem.where) {
            query[`${filterBase}[where]`] = fItem.where;
          }
          if (fItem.type === "orx" || fItem.type === "andx") {
            fItem.conditions.forEach((conditionItem, conditionKey) => {
              if (["in", "notin", "andx", "orx"].includes(conditionItem.type)) {
                if (["in", "notin"].includes(conditionItem.type)) {
                  query[`${filterBase}[conditions][${conditionKey}][type]`] =
                    conditionItem.type;
                  if (conditionItem.where) {
                    query[`${filterBase}[conditions][${conditionKey}][where]`] =
                      conditionItem.where;
                  }
                  if (conditionItem.field) {
                    query[`${filterBase}[conditions][${conditionKey}][field]`] =
                      conditionItem.field;
                  }
                  if (conditionItem.alias) {
                    query[`${filterBase}[conditions][${conditionKey}][alias]`] =
                      conditionItem.alias;
                  }
                  conditionItem.values?.forEach((valueItem, valueKey) => {
                    query[
                      `${filterBase}[conditions][${conditionKey}][values][${valueKey}]`
                    ] = encodeURIComponent(valueItem);
                  });
                }

                if (
                  conditionItem.type === "orx" ||
                  conditionItem.type === "andx"
                ) {
                  addNestedConditions(
                    conditionItem,
                    `${filterBase}[conditions][${conditionKey}]`
                  );
                }
              } else {
                Object.entries(conditionItem).forEach((filterItemProp) => {
                  if (filterItemProp[1] && !Array.isArray(filterItemProp[1])) {
                    query[
                      `${filterBase}[conditions][${conditionKey}][${filterItemProp[0]}]`
                    ] = encodeURIComponent(filterItemProp[1]);
                  } else {
                    // eslint-disable-next-line no-console
                    console.error(
                      `Query items need to be of type string, number or boolean:`,
                      filterItemProp,
                      conditionItem
                    );
                  }
                });
              }
            });
          }
        };
        if (filterItem.conditions) {
          addNestedConditions(filterItem, `filter[${filterKey}]`);
        }
      } else if (["in", "notin"].includes(filterItem.type)) {
        query[`filter[${filterKey}][type]`] = filterItem.type;
        if (filterItem.where) {
          query[`filter[${filterKey}][where]`] = filterItem.where;
        }
        if (filterItem.field) {
          query[`filter[${filterKey}][field]`] = filterItem.field;
        }
        if (filterItem.alias) {
          query[`filter[${filterKey}][alias]`] = filterItem.alias;
        }
        filterItem.values?.forEach((valueItem, valueKey) => {
          query[`filter[${filterKey}][values][${valueKey}]`] =
            encodeURIComponent(valueItem);
        });
      } else {
        Object.entries(filterItem).forEach((filterItemProp) => {
          if (filterItemProp[1] && !Array.isArray(filterItemProp[1])) {
            query[`filter[${filterKey}][${filterItemProp[0]}]`] =
              encodeURIComponent(filterItemProp[1]);
          } else {
            // eslint-disable-next-line no-console
            console.error(
              `Query items need to be of type string, number or boolean:`,
              filterItemProp,
              filterItem
            );
          }
        });
      }
    });
  }
  if (params) {
    Object.entries(params).forEach((queryEntry) => {
      if (queryEntry[1] !== undefined) {
        query[queryEntry[0]] = encodeURIComponent(queryEntry[1]);
      }
    });
  }
  return query;
};

const buildForCasaiam = (
  orderBy: OrderByItem[] | null,
  filter: FilterItem[] | null,
  params?: Params
) => {
  const query: Params = {};
  const orderField = orderBy?.[0]?.field;
  const orderDirection: "asc" | "desc" = orderBy?.[0]?.direction || "asc";

  if (orderField) {
    query[`order[${orderField}]`] = orderDirection;
  }

  if (filter) {
    filter.forEach((filterItem) => {
      switch (filterItem.type) {
        case "search":
          query[`s`] = filterItem.value || "";
          break;
        case "eq":
          query[`${filterItem.field}`] = filterItem.value || "";
          break;
        case "in":
          query[`${filterItem.field}`] = filterItem.values?.join() || "";
          break;

        default:
          break;
      }
    });
  }
  if (params) {
    Object.entries(params).forEach((queryEntry) => {
      if (queryEntry[1] !== undefined) {
        query[queryEntry[0]] = encodeURIComponent(queryEntry[1]);
      }
    });
  }
  return query;
};

const buildToUrl = (
  orderBy: OrderByItem[] | null,
  filter: FilterItem[] | null,
  params?: Params,
  fields?: string[]
) => {
  const query = build(orderBy, filter, params, fields);
  return Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join("&");
};

const buildToCasaiamUrl = (
  orderBy: OrderByItem[] | null,
  filter: FilterItem[] | null,
  params?: Params
) => {
  const query = buildForCasaiam(orderBy, filter, params);
  return Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join("&");
};

const queryBuilder = {
  build,
  buildToUrl,
  buildForCasaiam,
  buildToCasaiamUrl,
};

export default queryBuilder;
