import { createQuery } from "api/createEndpoint";
import { MESSAGE_SETTINGS_API_ROOT } from "../constants";
import { RemoveSingleUserMessageSettingsPayload } from "./types";

const query = createQuery<RemoveSingleUserMessageSettingsPayload>(
  ({ username }) => ({
    url: `${MESSAGE_SETTINGS_API_ROOT}/${username}`,
    method: "DELETE",
  })
);

export default query;
