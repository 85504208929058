import { Alert } from "@casasoft/styleguide/components/helpers-ux";
import { AlertProps } from "@casasoft/styleguide/components/helpers-ux/Alert";

interface MessageSettingsUpdatedAlertProps {
  text?: string;
  color?: AlertProps["color"];
  component?: React.ReactElement;
}

export const MessageSyncErrorAlert = ({
  text,
  color = "warning",
  component,
}: MessageSettingsUpdatedAlertProps) => {
  return (
    <Alert color={color}>
      {text ? <div>{text}</div> : null}
      {component ? component : null}
    </Alert>
  );
};
