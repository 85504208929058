import { sendEvent } from "../sendEvent";
import { CustomEventGroup } from "../types";

type MessagingCommonEventParams = {
  senderId: string;
  messageType: string | undefined;
  lang?: string;
};

type MessagingSentEventParams = MessagingCommonEventParams & {
  subjectLength?: number;
  bodyLength?: number;
  propertyId?: string;
  eventType?: string;
  attachmentsCount: number;
  hasReminder: boolean;
  phase?: string;
  relation?: string;
  addMessageHistory: boolean;
  sendContactLink: boolean;
  hasSignature: boolean;
  numRecipients: number;
};

export type MessagingEventParams = {
  start_message_flow: MessagingCommonEventParams & {
    propertyId?: string;
    recipientId?: string;
  };
  message_sent: MessagingSentEventParams;
  message_error: MessagingSentEventParams & {
    errorMessage?: string;
  };
};

const defaults: CustomEventGroup<MessagingEventParams> = {
  start_message_flow: {
    eventLabel: "Start Message Flow",
    eventParams: { lang: "de" },
  },
  message_sent: { eventLabel: "Send Message" },
  message_error: {
    eventLabel: "Send Message Error",
  },
};

export const sendMessagingEvent = (
  eventName: keyof MessagingEventParams,
  eventParams: MessagingEventParams[typeof eventName]
) => {
  sendEvent({
    ...defaults[eventName],
    ...{
      eventName,
      eventCategory: "messaging",
      eventParams: { ...defaults[eventName].eventParams, ...eventParams },
    },
  });
};
