export const SIGNATURE_BODY_START_TAG = "<!-- SIGNATURE START -->";
export const SIGNATURE_BODY_END_TAG = "<!-- SIGNATURE END -->";
export const ATTACHMENT_LINKS_TAG = "<kbd>{{attachmentLinks}}</kbd>";
export const SIGNATURE_TAGS_REGEX =
  /<!-- SIGNATURE START -->([\s\S]*)<!-- SIGNATURE END -->/g;

const replaceSignature = (content: string, signatureText = "", regex: RegExp) =>
  content.replace(
    regex,
    `${SIGNATURE_BODY_START_TAG}${signatureText}${SIGNATURE_BODY_END_TAG}`
  );

const appendSignature = (content: string, signatureText = "") =>
  `${content}${SIGNATURE_BODY_START_TAG}${signatureText}${SIGNATURE_BODY_END_TAG}`;

const eraseSignature = (content: string, regex: RegExp) =>
  content.replace(regex, "");

export const getInjectedBody = ({
  signatureText,
  body = "",
}: {
  signatureText?: string;
  body?: string;
}): string => {
  let newContent: string = body || "<p></p>"; // p tag, so there is a space to start typing

  const found = newContent.match(SIGNATURE_TAGS_REGEX);

  if (found && signatureText) {
    newContent = replaceSignature(
      newContent,
      signatureText,
      SIGNATURE_TAGS_REGEX
    );
  } else if (!found && signatureText) {
    newContent = appendSignature(newContent, signatureText);
  } else if (found && !signatureText) {
    newContent = eraseSignature(newContent, SIGNATURE_TAGS_REGEX);
  }

  return newContent;
};

export const clearCorruptedSignatureTags = ({
  body = "",
}: {
  body?: string;
}): string | undefined => {
  if (body && !body.match(SIGNATURE_TAGS_REGEX)) {
    let newContent = body;
    newContent = newContent.replace(SIGNATURE_BODY_START_TAG, "");
    newContent = newContent.replace(SIGNATURE_BODY_END_TAG, "");

    return newContent;
  }
};

export const insertAttachmentLinksTag = (
  body: string | undefined,
  hasAttachments?: boolean
): string | undefined => {
  const containsAttachmentLinks = body?.includes(ATTACHMENT_LINKS_TAG);
  if (!body || containsAttachmentLinks || !hasAttachments) {
    return body;
  }

  const found = body?.match(SIGNATURE_TAGS_REGEX);
  if (found) {
    return body.replace(
      SIGNATURE_BODY_START_TAG,
      `${ATTACHMENT_LINKS_TAG}${SIGNATURE_BODY_START_TAG}`
    );
  } else {
    return `${body}${ATTACHMENT_LINKS_TAG}`;
  }
};
