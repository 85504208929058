import { createQuery } from "api/createEndpoint";
import { MESSAGE_SETTINGS_API_ROOT } from "../constants";
import type { CreateSingleMessageSettingsPayload } from "./types";

const query = createQuery<CreateSingleMessageSettingsPayload>(
  ({ type, body }) => {
    return {
      url: `${MESSAGE_SETTINGS_API_ROOT}/${type}`,
      method: "POST",
      data: body,
    };
  }
);

export default query;
