import { useRef } from "react";
import { selectContactLang } from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";
import { i18n } from "utilities";
import { FALLBACK_LANG } from "utilities/i18n";

export const useMessageFormLanguage = () => {
  const languageCache = useRef<string[]>([]);
  const userLanguage = useAppSelector(selectContactLang);

  const loadRecipientLanguage = async (
    formLang: string | undefined
  ): Promise<string> => {
    const recipientsLang = formLang || userLanguage || FALLBACK_LANG;

    if (!languageCache.current.includes(recipientsLang)) {
      await new Promise<void>((res) => {
        i18n.loadLanguages([recipientsLang], () => {
          res();
        });
      });
      languageCache.current.push(recipientsLang);
    }

    return recipientsLang;
  };

  return { loadRecipientLanguage };
};
