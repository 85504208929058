import { createAction } from "@reduxjs/toolkit";

export const resetResourceState = createAction(
  "@APP/RESET_RESOURCE_STATE",
  (resource: string) => {
    return {
      payload: {
        resource,
      },
    };
  }
);

export const changeResourceSearch = createAction(
  "@APP/CHANGE_RESOURCE_SEARCH",
  (resource: string, term: string, field?: string) => {
    return {
      payload: {
        resource,
        term,
        field,
      },
    };
  }
);

export const changeResourcePageSize = createAction(
  "@APP/CHANGE_RESOURCE_PAGESIZE",
  (resource: string, pageSize: number) => {
    return {
      payload: {
        resource,
        pageSize,
      },
    };
  }
);

export const changeResourcePage = createAction(
  "@APP/CHANGE_RESOURCE_PAGE",
  (resource: string, page: number) => {
    return {
      payload: {
        resource,
        page,
      },
    };
  }
);

export const changeResourceSort = createAction(
  "@APP/CHANGE_RESOURCE_SORT",
  (resource: string, key: string | null, dir: "asc" | "desc" | null) => {
    return {
      payload: {
        resource,
        key,
        dir,
      },
    };
  }
);
