const toolbarCore = "undo redo | bold italic underline strikethrough";
const toolbarCoreWithList = toolbarCore + " | bullist numlist link charmap";
const toolbarLong =
  toolbarCore +
  " | fontsize lineheight forecolor backcolor align code | cut copy | bullist numlist link charmap";
const toolbarMessage =
  toolbarCore +
  " | fontsize lineheight forecolor backcolor align code | cut copy | mustacheTags | bullist numlist link charmap";
const toolbarTemplate = toolbarCoreWithList + " | mustacheTags";
const toolbarSignature = toolbarCoreWithList + " | image paste code preview";
const toolbarContentBreak = toolbarCoreWithList + " | contentBreaks";
const toolbarPropertyContent =
  "undo redo | bold italic | bullist numlist link charmap | contentBreaks";

const TOOLBAR = {
  long: toolbarLong,
  message: toolbarMessage,
  template: toolbarTemplate,
  signature: toolbarSignature,
  short: toolbarContentBreak,
  property: toolbarPropertyContent,
} as const;

interface GetToolbarProps {
  toolbar?: keyof typeof TOOLBAR;
  isDisabled?: boolean;
}

export const getToolbar = ({
  toolbar = "long",
  isDisabled = false,
}: GetToolbarProps = {}): string | boolean => {
  return isDisabled ? false : TOOLBAR[toolbar];
};

export const tinymceManager = {
  getToolbar,
};
