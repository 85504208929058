import { useAppSelector } from "redux/hooks";
import { selectCasaoneUser } from "redux/auth/selectors";
import { useTranslation } from "react-i18next";
import { mapPropertyAttachments } from "./propertyAttachmentsManager";
import { useState } from "react";
import {
  getCleanedUpMedia,
  getEventPhase,
  transformReminderToPayload,
} from "./miscHelpers";
import { getMessageAttendees } from "./attendeesManager";
import { EventShape } from "entities/event/types";
import { type MessageCreateFormShape } from "../../MessageCreateForm";
import { useMessageAnalytics } from "../useMessagingAnalytics";
import { insertAttachmentLinksTag } from "../signatureManager";
import { mergeMessageBodyWithHistory } from "./messageBodyManager";
import { isApiErrorDefinition } from "@casasoft/styleguide/utilities/api-error/apiErrorDefinitions";
import { FormModalOnFail } from "@casasoft/styleguide/utilities/api-error/handleFormModalError";
import { EventMutationShape } from "api/entities/event/endpoints/create-single/types";
import { useSendMessageMutation } from "api/entities/message";
import { SentMessageMetaData } from "components/contact/features/contact-list/hooks/useSendMessageCallbacks";
import { useMessageContactManager } from "./useMessageContactManager";
import { useMessageFormLanguage } from "./useMessageFormLanguage";
import {
  Alerts,
  useAlert,
} from "@casasoft/styleguide/components/helpers-ux/hooks/useAlert";

export type OnDoneArg = EventShape | SentMessageMetaData[];

interface UseMessageFormOnSubmitProps {
  thread?: EventShape; // undefined unless this is a reply to a thread
  onDone: () => void;
  onFail: FormModalOnFail;
}

export const useMessageFormOnSubmit = ({
  thread,
  onDone,
  onFail,
}: UseMessageFormOnSubmitProps) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const senderUserId = useAppSelector(selectCasaoneUser)?.contact?.id;
  const [isSending, setIsSending] = useState(false);
  const { loadRecipientLanguage } = useMessageFormLanguage();
  const { getContactQueryData } = useMessageContactManager();
  const {
    sendMessageSentAnalytics,
    sendMessageErrorAnalytics,
    getMessageAnalyticsParams,
  } = useMessageAnalytics();
  const [sendMessage] = useSendMessageMutation();

  const onSubmit = async (data: MessageCreateFormShape) => {
    const contacts: string[] | undefined = data.contact?.map((c) => c.value);
    if (!contacts) return;

    setIsSending(true);

    const recipientsLang: string = await loadRecipientLanguage(data.lang);

    const attachments = getCleanedUpMedia(data.attachments);
    const propertyAttachments = await mapPropertyAttachments({
      propertyAttachments: data.propertyAttachments,
      property: data.property?.property,
      t,
    });
    const mergedAttachments = [...propertyAttachments, ...attachments];

    const messages = [];
    for (const contactString of contacts) {
      const { contactLang, contactAccessUrls } = getContactQueryData({
        contactString,
        recipientsLang,
        formData: data,
      });

      const message: EventMutationShape = {
        html: mergeMessageBodyWithHistory({
          body: insertAttachmentLinksTag(data.body, !!mergedAttachments.length),
          messageHistory: data.messageHistory,
          addMessageHistoryFlag: data.addMessageHistory,
        }),
        eventType: data.eventType,
        type: data.eventBaseType,
        property: data.property?.value,
        subject: data.subject,
        attachments: mergedAttachments,
        urls: contactAccessUrls,
        eventTrigger: thread?.id,
        lang: contactLang,
        attendees: getMessageAttendees(data, senderUserId, contactString),
        eventPhase: getEventPhase(data.phase), // if removed or changed
        requestedRelationType: data.relation,
      };

      messages.push(message);
    }

    const analyticsEventParams = getMessageAnalyticsParams(
      data,
      mergedAttachments
    );

    try {
      await sendMessage({
        reminder: transformReminderToPayload(
          data.addReminderForm,
          senderUserId
        ),
        messages,
      }).unwrap();
      sendMessageSentAnalytics(analyticsEventParams);
      showAlert(Alerts.MESSAGE_PENDING);
      onDone();
    } catch (err: unknown) {
      if (isApiErrorDefinition(err)) {
        sendMessageErrorAnalytics({
          ...analyticsEventParams,
          errorMessage: err.message,
        });
        onFail(err);
      } else if (err instanceof Error) {
        sendMessageErrorAnalytics({
          ...analyticsEventParams,
          errorMessage: err.message,
        });
        onFail({ text: err.message, type: "error" });
      }
    } finally {
      setIsSending(false);
    }
  };

  return { onSubmit, isSending };
};
