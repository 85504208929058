import { ContactAccessUrl } from "api/entities/event/endpoints/create-single/types";

export const getContactAccessUrl = ({
  sendContactLink,
  language,
  label,
}: {
  sendContactLink: boolean;
  language: string;
  label: string;
}): ContactAccessUrl[] => {
  const urls: ContactAccessUrl[] = [];

  if (sendContactLink) {
    const url = window.location.href;
    const urlArr = url.split("/");

    urls.push({
      url: `${urlArr[0]}//${urlArr[2]}/public/${language}/edit-contact/{{recipient.accessKey}}`,
      title: "edit-my-profile",
      label,
    });
  }

  return urls;
};
