import { type AlertItem } from "./helpers/models";
import { useOverdueInvoiceWarning } from "./helpers/useOverdueInvoiceWarning";
import { useMaintenanceWarning } from "./helpers/useMaintenanceWarning";
import {
  TECHNICAL_ISSUE_KEY,
  useTechnicalIssuesWarning,
} from "./helpers/useTechnicalIssuesWarning";
import { Notification } from "@casasoft/styleguide/components/notification";
import { useCookies } from "react-cookie";
import Warning from "./components/Warning";

export const PageNotificationBanners = () => {
  const [cookies] = useCookies();
  const { overdueInvoiceWarning } = useOverdueInvoiceWarning();
  // const { maintenanceWarning } = useMaintenanceWarning();
  const { maintenanceWarning: componentWarning } = useMaintenanceWarning({
    component: <Warning />,
    colorType: "success",
    cookieId: "cs_technical_issues_warning_23_1_25",
  });
  const { technicalIssuesWarning } = useTechnicalIssuesWarning({
    textKey: TECHNICAL_ISSUE_KEY.images_issue_22_1_25,
    isEnabled: false,
    colorType: "danger",
    cookieId: "cs_technical_issues_danger_22_1_25",
  });

  const alertItems: AlertItem[] = [];

  if (overdueInvoiceWarning) {
    alertItems.push(overdueInvoiceWarning);
  }
  // if (maintenanceWarning) {
  //   alertItems.push(maintenanceWarning);
  // }
  if (componentWarning) {
    alertItems.push(componentWarning);
  }
  if (technicalIssuesWarning) {
    alertItems.push(technicalIssuesWarning);
  }

  const filteredAlertItems = alertItems.filter(
    (alert) => cookies[alert.cookieId] !== "1"
  );
  if (!filteredAlertItems) {
    return null;
  }

  return (
    <div className="tw-flex-shrink-0 tw-flex-grow-0">
      {filteredAlertItems.map((alert) => (
        <Notification
          key={alert.cookieId}
          colorType={alert.colorType}
          cookieId={alert.cookieId}
          text={alert.text}
          onClick={alert.onClick}
        />
      ))}
    </div>
  );
};
