import type {
  PropertyAttachment,
  PropertyDocumentationAttachment,
  PropertyFlyerAttachment,
} from "@casasoft/styleguide/components/legacy/MediaChooserPropertyAttachments/helpers/propertyAttachment.model";
import {
  ATTACHMENT_DOCUMENTATION_KEY,
  ATTACHMENT_FLYER_KEY,
  STATIC_PROPERTY_ATTACHMENT_KEYS,
} from "@casasoft/styleguide/components/legacy/MediaChooserPropertyAttachments/helpers/useStaticPropertyAttachments";
import type { PropertyListReducedShape } from "api/entities/property/endpoints/list-reduced/types";
import { getDossierMedia } from "./dossierMedia";
import { TFunction } from "i18next";
import type { MessageAttachment } from "api/entities/event/endpoints/create-single/types";

const getFlyerOrDocMediaId = async ({
  attachment,
  property,
}: {
  attachment: PropertyFlyerAttachment | PropertyDocumentationAttachment;
  property: PropertyListReducedShape | undefined;
}): Promise<string | undefined> => {
  const propertyId = property?.id;
  const lang = attachment.language;

  const options: any = {
    format: attachment.format,
    showPrice: attachment.addPrice,
    showAddress: attachment.addAddress,
    showHeaderFooter: attachment.showHeaderFooter,
    type: attachment.id === ATTACHMENT_FLYER_KEY ? "flyer" : "dossier",
  };

  if (attachment && attachment.id === ATTACHMENT_DOCUMENTATION_KEY) {
    options.showAffordability = (
      attachment as PropertyDocumentationAttachment
    ).addAffordabilityCalculation;
  }

  if (attachment && propertyId) {
    const flyerOrDocMedia = await getDossierMedia(propertyId, options, lang);

    return flyerOrDocMedia?.id;
  }

  return undefined;
};

const mapFlyerOrDocAttachment = async ({
  attachment,
  property,
  t,
}: {
  attachment: PropertyFlyerAttachment | PropertyDocumentationAttachment;
  property: PropertyListReducedShape | undefined;
  t: TFunction;
}): Promise<MessageAttachment | undefined> => {
  const isFlyer = attachment.id === ATTACHMENT_FLYER_KEY;
  const mediaId = await getFlyerOrDocMediaId({ attachment, property });
  const label: string = isFlyer
    ? t("Flyer", { lng: attachment.language })
    : t("Documentation", { lng: attachment.language });

  if (mediaId) {
    return {
      alt: label,
      title: label,
      rank: isFlyer ? 1 : 0,
      media: mediaId,
    };
  }
};

const mapDefaultAttachment = (
  attachment: PropertyAttachment
): MessageAttachment => ({
  title: attachment.title,
  rank: 2,
  media: attachment.id,
  alt: attachment.title,
});

export const mapPropertyAttachments = async ({
  propertyAttachments,
  property,
  t,
}: {
  propertyAttachments: PropertyAttachment[];
  property: PropertyListReducedShape | undefined;
  t: TFunction;
}): Promise<MessageAttachment[]> => {
  const mappedAttachments = propertyAttachments
    .filter((item) => !STATIC_PROPERTY_ATTACHMENT_KEYS.includes(item.id))
    .map((item) => mapDefaultAttachment(item));

  const mappedSpecialAttachments = await Promise.all(
    propertyAttachments
      .filter((item) => STATIC_PROPERTY_ATTACHMENT_KEYS.includes(item.id))
      .map(async (item) =>
        mapFlyerOrDocAttachment({
          attachment: item as
            | PropertyFlyerAttachment
            | PropertyDocumentationAttachment,
          property,
          t,
        })
      )
  );

  return [...mappedSpecialAttachments, ...mappedAttachments].filter(
    (item) => !!item
  ) as MessageAttachment[];
};
