import { type FormRule } from "hooks/useRules";
import { i18n } from "utilities";

const VALID_HOUR_FORMAT_REGEX = /^[0-9]{1,2}:[0-9]{2}$/;

export const timeFormatRule = (): FormRule => ({
  validate: (value: string) => {
    // allow empty value
    if (!value) {
      return;
    }

    if (!VALID_HOUR_FORMAT_REGEX.test(value)) {
      return i18n.t("Please input according to the requested format") as string;
    }
  },
});
