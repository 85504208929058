import axios from "axios";

import { startTimeRequestInterceptor } from "./issueTrackerInterceptors";

const axiosPublicInstance = axios.create();

// track request time + size
axiosPublicInstance.interceptors.request.use(startTimeRequestInterceptor);

export default axiosPublicInstance;
