import { isValidDateString } from "utilities/dateTime";
import moment from "moment/moment";

export const getDisableMatcher = ({
  rangeStart,
  rangeEnd,
  todayDate = new Date(),
}: {
  rangeStart?: string;
  rangeEnd?: string;
  todayDate?: Date;
} = {}) => {
  const rangeStartDate = isValidDateString(rangeStart);
  const rangeEndDate = isValidDateString(rangeEnd);

  if (rangeStartDate && rangeEndDate) {
    return { before: rangeStartDate, after: rangeEndDate };
  }

  if (rangeStartDate) {
    return { before: rangeStartDate };
  }

  if (rangeEndDate) {
    return { after: rangeEndDate };
  }
  // default all dates available
  return false;
};

export const getParsedDateValue = (
  format: "DD.MM.YYYY" | "YYYY-MM-DD",
  value?: string
) => {
  if (!value) {
    return "";
  }

  if (isValidDateFormat(value)) {
    if (format === "DD.MM.YYYY") {
      return value;
    }

    const date = new Date(value.split(".").reverse().join("-"));
    return date.toISOString().split("T")[0];
  }

  return moment(value).isValid() ? moment(value).format(format) : value;
};

export const isValidDateFormat = (dateString: string) => {
  const regex =
    /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.(19[0-9]{2}|20[0-9]{2}|21[0-4][0-9]|2150)$/;
  return regex.test(dateString);
};
