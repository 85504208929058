import axios from "axios";
import authTokensRequestInterceptor from "./authTokensInterceptor";
import { startTimeRequestInterceptor } from "./issueTrackerInterceptors";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(authTokensRequestInterceptor);
// track request time + size
axiosInstance.interceptors.request.use(startTimeRequestInterceptor);

export default axiosInstance;
