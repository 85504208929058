import { useTranslation } from "react-i18next";
import { Spinner } from "@casasoft/styleguide/components/helpers-ux";
import ContactSearchprofilesCard from "../contact/ContactSearchprofilesCard";
import AddressCard from "../miscellaneous/address/AddressCard";
import resourceHelper from "../../utilities/resource";
import Config from "config";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useEffect } from "react";
import { useParams } from "react-router";
import ContactDetailsCard from "components/contact/ContactDetailsCard";

function EditContactWithAccessKey() {
  const { t, i18n } = useTranslation();
  const { accessKey, lang } = useParams<{ accessKey: string; lang: string }>();

  const { contactWitchAccesskeyStore } = useSelector((state: RootState) => ({
    contactWitchAccesskeyStore: state.contactsWithAccesskey,
  }));

  useEffect(() => {
    async function asyncWrapper() {
      try {
        i18n.changeLanguage(lang || "de");
        const elem = await resourceHelper.getItem(
          "contactsWithAccesskey",
          accessKey,
          true
        );
        if (elem && elem.lang) {
          i18n.changeLanguage(elem.lang);
        }
      } catch (err) {
        const error = new Error(`get item contactsWithAccesskey failed`);
        Object.assign(error, {
          error: err,
        });
        throw err;
      }
    }
    asyncWrapper();
  }, [i18n, accessKey, lang]);

  const contact = contactWitchAccesskeyStore.item || null;

  if (contactWitchAccesskeyStore.isFetchingItem) {
    return <Spinner />;
  }

  if (!contact) {
    return (
      <div
        style={{
          height: "100vh",
          overflowY: "auto",
          padding: "2rem",
        }}
      >
        <div
          style={{
            maxWidth: "50rem",
            margin: "auto",
          }}
        >
          <h1>{t("For security reasons, this link is no longer valid.")}</h1>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        height: "100vh",
        overflowY: "auto",
        padding: "2rem",
      }}
    >
      <div
        style={{
          maxWidth: "50rem",
          margin: "auto",
        }}
      >
        <div
          className="tw-flex tw-flex-wrap-reverse"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="tw-mb-4">
            <h1>{t("Edit my profile")}</h1>
          </div>
          <div
            className="tw-mb-4"
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              height={45}
              alt="logo"
              src={`${Config.casaiamUrl}/media/company/${Config.customerKey}/logo.svg`}
              style={{
                maxWidth: "100%",
              }}
            />
          </div>
        </div>
        <p>
          {t(
            "We were already in touch because you were looking for a property. We would like to help you gain further suitable recommendations. Are your personal details up to date? Simply adjust your particulars and search profiles here. You may close the browser window once you're done."
          )}
        </p>
        <ContactDetailsCard
          contact={contact}
          publicContactSettings={{
            onSubmit: async (data, dirtyData) => {
              await resourceHelper.updateItem("contactsWithAccesskey", {
                id: contact.id,
                ...dirtyData,
              });
            },
          }}
        />
        <div className="tw-mb-4" />
        <AddressCard
          storeName="addressesWithAccesskey"
          contactAccessKey={accessKey}
          entity={contact._embedded?.address || null}
          onAfterCreate={(body: any) => {
            resourceHelper.updateItem(
              "contactsWithAccesskey",
              {
                id: contact.id,
                address: body.id,
              },
              true
            );
          }}
          onAfterUpdate={() => {
            resourceHelper.getItem("contactsWithAccesskey", accessKey, true);
          }}
          postOfficeBoxField
          pinUpdateAddress={false}
        />
        <div className="tw-mb-4" />
        <ContactSearchprofilesCard
          storeName="contactSearchprofilesWithAccesskey"
          contactAccessKey={accessKey}
          entity={contact}
        />
      </div>
    </div>
  );
}

export default EditContactWithAccessKey;
