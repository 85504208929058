import { datadogRum } from "@datadog/browser-rum";
import Config, { Env } from "config";

const init = () => {
  if (
    Config.env === Env.production &&
    process.env.REACT_APP_DATADOG_APPLICATION_ID &&
    process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ) {
    datadogRum.init({
      //https://app.datadoghq.eu/rum/application/c6f687fa-9198-43dd-b32a-bcaeb293c829/sdk?fromUser=false&from_ts=1742891109515&to_ts=1743495909515&live=true
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      //see https://docs.datadoghq.com/getting_started/site/
      site: "datadoghq.eu",
      //Linked to the application name in the datadog console
      service: "casaone",
      //We track all the environments in the same datadog console
      env: "production",
      //Default values to refresh the datadog console
      sessionSampleRate: 100,
      sessionReplaySampleRate: 5,
      //Enable events, network resources and performance metrics collection
      trackUserInteractions: true,
      //Instrumenting DOM elements using this attribute here
      actionNameAttribute: "data-datadog-action",
      trackResources: true,
      trackLongTasks: true,
      //No cookie consent is required for the app, so we can set this to granted
      trackingConsent: "granted",
      defaultPrivacyLevel: "mask",
      //At build time, the version of the app is injected into the code
      version: process.env.REACT_APP_DATADOG_VERSION,
    });
  }
};

export default init;
