import { casaoneApi } from "api";
import sendMessageQuery from "./endpoints/send-message/query";
import fetchMessageQuery from "./endpoints/fetch/query";
import sendMessageInvalidatesTags from "./endpoints/send-message/invalidatesTags";
import fetchMessageInvalidatesTags from "./endpoints/fetch/invalidatesTags";

const messageApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    sendMessage: builder.mutation({
      query: sendMessageQuery,
      invalidatesTags: sendMessageInvalidatesTags,
    }),
    fetchMessage: builder.mutation({
      query: fetchMessageQuery,
      invalidatesTags: fetchMessageInvalidatesTags,
    }),
  }),
  overrideExisting: false,
});

export const { useSendMessageMutation, useFetchMessageMutation } = messageApi;
