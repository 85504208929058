import { selectCasaoneUser } from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";
import {
  MessagingEventParams,
  sendMessagingEvent,
} from "utilities/analytics/messaging/sendMessagingEvent";
import { type MessageCreateFormShape } from "../MessageCreateForm";
import { isEmptyAddReminderForm } from "components/message/features/add-reminder/AddReminderForm/helpers/isEmptyAddReminderForm";
import { MessageAttachment } from "api/entities/event/endpoints/create-single/types";

export type MessageEventParams<T extends keyof MessagingEventParams> = Omit<
  MessagingEventParams[T],
  "senderId"
>;

export const useMessageAnalytics = () => {
  const senderUserId = useAppSelector(selectCasaoneUser)?.contact?.id;

  const getMessageAnalyticsParams = (
    formData: Partial<MessageCreateFormShape>,
    mergedAttachments: MessageAttachment[]
  ): MessageEventParams<"message_sent"> => ({
    subjectLength: formData.subject?.length || 0,
    bodyLength: formData.body?.length || 0,
    messageType: formData.eventBaseType,
    eventType: formData.eventType,
    propertyId: formData.property?.value,
    hasReminder: !isEmptyAddReminderForm(formData.addReminderForm),
    attachmentsCount: mergedAttachments.length,
    phase: formData.phase,
    relation: formData.relation,
    addMessageHistory: !!formData.addMessageHistory,
    sendContactLink: !!formData.sendContactLink,
    hasSignature: !!formData.signature,
    numRecipients: formData.contact?.length || 0,
  });

  const sendEventWrapper = (
    eventName: keyof MessagingEventParams,
    options: MessageEventParams<typeof eventName>
  ) => {
    if (!senderUserId) {
      return;
    }

    sendMessagingEvent(eventName, {
      ...options,
      senderId: senderUserId,
    });
  };

  return {
    sendStartMessageFlowAnalytics: (
      options: MessageEventParams<"start_message_flow">
    ) => sendEventWrapper("start_message_flow", options),
    sendMessageSentAnalytics: (options: MessageEventParams<"message_sent">) =>
      sendEventWrapper("message_sent", options),
    sendMessageErrorAnalytics: (options: MessageEventParams<"message_error">) =>
      sendEventWrapper("message_error", options),
    getMessageAnalyticsParams,
  };
};
