import { EventMutationShape } from "api/entities/event/endpoints/create-single/types";
import { type FormShape } from "components/event/EventCreateForm";
import { getAttendeesUpdatePayload } from "components/event/helpers/attendees";
import { mapSenderRecipient } from "components/event/helpers/mapSenderRecipient";
import { convertStringToNamedTags } from "components/miscellaneous/tags/features/helpers/tagParser";
import csMoment from "utilities/csMoment";
import { PartialOrNull } from "utilities/type-helpers/object-oberators";

interface mapEventCreateFormDataToRequestDataProps {
  data: FormShape;
  dirtyData: PartialOrNull<FormShape>;
  inferredPhase?: string;
  typeOfRequest?: "create" | "edit";
}

// TODO: I just exported the code from monstrosity EventCreateForm.tsx to this file
// Once properly covered with tests it should be refactored a bit, there are some nonsensical parts
export const mapEventCreateFormDataToRequestData = ({
  data,
  dirtyData,
  inferredPhase,
  typeOfRequest = "create",
}: mapEventCreateFormDataToRequestDataProps): {
  requestData: EventMutationShape;
  reminderRequestData: EventMutationShape;
} => {
  const {
    eventPhase,
    eventBaseType,
    eventType,
    startDate,
    startTime,
    duration,
    tags: tagsString,
    user,
    contact,
    relationType,
    addReminder,
    reminderHtml,
    reminderStartDate,
    reminderStartTime,
    reminderSubject,
    reminderUser,
    relationPhase,
    propertyMarketingMethod, // extract so it wont get patched
    ...dataToPatch
  } = typeOfRequest === "edit" ? dirtyData : data; // use dirtyData to patch if we are editing, to create use full data

  const startDateTime =
    typeOfRequest === "create"
      ? `${data.startDate} ${data.startTime}`
      : undefined;

  const attendees = getAttendeesUpdatePayload({
    dirtyData: mapSenderRecipient(dirtyData, data.eventBaseType),
    data: mapSenderRecipient(data, data.eventBaseType),
  });

  const transformedData = {
    attendees,
    start:
      dirtyData?.startDate || dirtyData?.startTime
        ? `${dirtyData.startDate || data.startDate} ${
            data.startTime || "00:00"
          }`
        : startDateTime,
    end: duration
      ? csMoment(`${data.startDate} ${data.startTime || "00:00"}`) // take from data, since dirtyData might not contain start data
          .add(duration, "minutes")
          .format("YYYY-MM-DD HH:mm")
      : undefined,
    tags: convertStringToNamedTags(tagsString),
    type: eventBaseType,
    eventType: eventType,
    eventPhase:
      inferredPhase !== undefined && inferredPhase !== null // if removed or changed
        ? {
            id: data.eventPhase,
            phase: inferredPhase,
          }
        : undefined,
  };

  return {
    requestData: { ...dataToPatch, ...transformedData },
    reminderRequestData: {
      addReminder,
      reminderHtml,
      reminderStartDate,
      reminderStartTime,
      reminderSubject,
      reminderUser,
      relationPhase,
    },
  };
};
