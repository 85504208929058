import { ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "@casasoft/styleguide/components/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faChartPie,
  faTachometerAlt,
  faImages,
  faHome,
  faUsersCog,
  faAddressBook,
  faEnvelope,
  faFileInvoice,
  faNewspaper,
} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import QuickaddMenu from "./QuickaddMenu";
import { BASE_DATA_TYPES, PUBLICATION_TYPES } from "utilities/propertyTypes";
import { RootState } from "redux/store";
import { getMinimumRequiredPlan, hasAccessTo } from "utilities/featureFlags";
import TeaserNavItem from "./TeaserNavItem";
import { PROPERTY_TOUR_QUICKADD_MENU } from "tours/property-onboarding/propertyCreateOnboarding";
import { DataAttributes } from "@casasoft/styleguide/utilities/extractDataAttributes";
import { PROPERTY_TOUR_DETAILS_PROPERTY_NAV_ITEM } from "tours/property-onboarding/propertyDetailsOnboarding";

interface AppNavProps {
  onToggle: () => void;
  collapsed: boolean;
  navToggle: boolean;
}

const AppNav = ({
  onToggle,
  collapsed = false,
  navToggle = false,
}: AppNavProps) => {
  const { t } = useTranslation();
  const items: ({ node: ReactNode } & DataAttributes)[] = [];
  const { auth } = useSelector((state: RootState) => {
    return {
      auth: state.auth,
    };
  });
  const { pathname } = useLocation();
  const isCrm =
    auth?.profile?.data?.company?.id === "casasoft" &&
    auth?.profile?.data?.apiCompanySection === "casasoft";

  if (
    !isCrm &&
    (hasAccessTo("propertyManagement") ||
      hasAccessTo("contactManagement") ||
      hasAccessTo("messageManagement") ||
      hasAccessTo("eventManagement") ||
      hasAccessTo("mediaManagement"))
  ) {
    items.push({
      node: <QuickaddMenu />,
      "data-boarding": PROPERTY_TOUR_QUICKADD_MENU,
    });
  }
  items.push({
    node: (
      <NavLink
        activeClassName="selected"
        key="/dash"
        to="/dash"
        data-datadog-action="Dashboard left-side nav"
      >
        <FontAwesomeIcon fixedWidth icon={faTachometerAlt} />
        {t("Dashboard")}
      </NavLink>
    ),
  });
  if (!isCrm) {
    if (hasAccessTo("propertyManagement")) {
      items.push({
        "data-boarding": PROPERTY_TOUR_DETAILS_PROPERTY_NAV_ITEM,
        node: (
          <NavLink
            data-datadog-action="Properties left-side nav"
            activeClassName="selected"
            key="/property"
            to="/property"
            isActive={() => {
              const availablePathNames = [
                ...BASE_DATA_TYPES.map((aBaseType) => `/${aBaseType}/list`),
              ];

              return !!availablePathNames.find((availablePath) =>
                pathname.startsWith(availablePath)
              );
            }}
          >
            <FontAwesomeIcon fixedWidth icon={faHome} />
            {t("Properties")}
          </NavLink>
        ),
      });
      items.push({
        node: (
          <NavLink
            data-datadog-action="Publications left-side nav"
            activeClassName="selected"
            key={`/publication`}
            to={`/publication`}
            isActive={() => {
              const availablePathNames = [
                `/publication`,
                ...PUBLICATION_TYPES.map((aPubType) => `/${aPubType}/list`),
              ];

              return !!availablePathNames.find((availablePath) =>
                pathname.startsWith(availablePath)
              );
            }}
          >
            <FontAwesomeIcon fixedWidth icon={faNewspaper} />
            {t("Publications")}
          </NavLink>
        ),
      });
    }
    const minimumContactManagement =
      getMinimumRequiredPlan("contactManagement");
    if (!minimumContactManagement) {
      items.push({
        node: (
          <NavLink
            data-datadog-action="Contacts left-side nav"
            activeClassName="selected"
            key="/contact"
            to="/contact"
            isActive={() => {
              const availablePathNames = [
                "/contact/list",
                "/contact/event/list",
                "/contact/phase/list",
              ];

              return !!availablePathNames.find((availablePath) =>
                pathname.startsWith(availablePath)
              );
            }}
          >
            <FontAwesomeIcon fixedWidth icon={faAddressBook} />
            {t("Contacts")}
          </NavLink>
        ),
      });
    } else if (minimumContactManagement !== "NOACCESS") {
      items.push({
        node: (
          <TeaserNavItem
            icon={faAddressBook}
            label={t("Contacts")}
            planToAccess={minimumContactManagement}
          />
        ),
      });
    }
    const minimumMessageManagement =
      getMinimumRequiredPlan("messageManagement");
    if (!minimumMessageManagement) {
      items.push({
        node: (
          <NavLink
            data-datadog-action="Messages left-side nav"
            activeClassName="selected"
            key="/message"
            to="/message"
          >
            <FontAwesomeIcon fixedWidth icon={faEnvelope} />
            {t("Messages")}
          </NavLink>
        ),
      });
    } else if (minimumMessageManagement !== "NOACCESS") {
      items.push({
        node: (
          <TeaserNavItem
            icon={faEnvelope}
            label={t("Messages")}
            planToAccess={minimumMessageManagement}
          />
        ),
      });
    }
  }

  const minimumEventManagement = getMinimumRequiredPlan("eventManagement");
  if (!minimumEventManagement) {
    items.push({
      node: (
        <NavLink
          data-datadog-action="Logbook left-side nav"
          activeClassName="selected"
          key="/event"
          to="/event"
        >
          <FontAwesomeIcon fixedWidth icon={faBook} />
          {t("Logbook")}
        </NavLink>
      ),
    });
  } else if (minimumEventManagement !== "NOACCESS") {
    items.push({
      node: (
        <TeaserNavItem
          icon={faBook}
          label={t("Logbook")}
          planToAccess={minimumEventManagement}
        />
      ),
    });
  }

  if (!isCrm) {
    const minimumReportManagement = getMinimumRequiredPlan("reportManagement");
    if (!minimumReportManagement) {
      items.push({
        node: (
          <NavLink
            data-datadog-action="Reporting left-side nav"
            activeClassName="selected"
            key="/reports"
            to="/reports"
          >
            <FontAwesomeIcon fixedWidth icon={faChartPie} />
            {t("Reporting")}
          </NavLink>
        ),
      });
    } else if (minimumReportManagement !== "NOACCESS") {
      items.push({
        node: (
          <TeaserNavItem
            icon={faChartPie}
            label={t("Reporting")}
            planToAccess={minimumReportManagement}
          />
        ),
      });
    }
  }
  if (!isCrm && hasAccessTo("mediaManagement")) {
    items.push({
      node: (
        <NavLink
          data-datadog-action="Medias left-side nav"
          activeClassName="selected"
          key="/media"
          to="/media"
        >
          <FontAwesomeIcon fixedWidth icon={faImages} />
          {t("Medias")}
        </NavLink>
      ),
    });
  }

  if (isCrm) {
    items.push({
      node: (
        <NavLink
          data-datadog-action="Customers left-side nav"
          activeClassName="selected"
          key="/admin-super"
          to="/admin-super"
        >
          <FontAwesomeIcon fixedWidth icon={faUsersCog} />
          {t("Customers")}
        </NavLink>
      ),
    });
  }

  if (isCrm && hasAccessTo("invoiceManagement")) {
    items.push({
      node: (
        <NavLink
          data-datadog-action="Invoices left-side nav"
          activeClassName="selected"
          exact={true}
          key="/invoices/all"
          to="/invoices/all"
        >
          <FontAwesomeIcon fixedWidth icon={faFileInvoice} />
          {t("Invoices")}
        </NavLink>
      ),
    });
  }
  return (
    <Nav
      items={items}
      onToggle={onToggle}
      collapsed={collapsed}
      navToggle={navToggle}
    />
  );
};

export default AppNav;
