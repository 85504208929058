import { casaoneApi } from "api";

import iaziCommonDataRequestQuery from "./endpoints/get-common-data/query";
import iaziTextStylesRequestQuery from "./endpoints/get-text-styles/query";
import iaziGenerateTextRequestQuery from "./endpoints/post-generate-text/query";
import iaziCommonDataRequestProvidesTags from "./endpoints/get-common-data/providesTags";
import iaziPrevalidationRequestQuery from "./endpoints/post-prevalidation/query";
import iaziPrevalidationRequestProvidesTags from "./endpoints/post-prevalidation/providesTags";
import iaziMunicipalityInfoRequestQuery from "./endpoints/municipality-info/query";
import iaziAddressValidationRequestQuery from "./endpoints/address-validation/query";
import iaziModelReportRequestQuery from "./endpoints/model-report/query";
import iaziLoreReportRequestQuery from "./endpoints/lore-report/query";
import transformResponse from "./endpoints/get-common-data/transformResponse";
import type {
  IaziMunicipalityInfoRequestBody,
  IaziMunicipalityInfoResponse,
} from "./endpoints/municipality-info/types";
import type {
  IaziAddressValidationRequestBody,
  IaziAddressValidationResponse,
} from "./endpoints/address-validation/types";
import type {
  IaziModelReportRequestBody,
  IaziModelReportResponse,
} from "./endpoints/model-report/types";
import type {
  IaziLoreReportRequestBody,
  IaziLoreReportResponse,
} from "./endpoints/lore-report/types";

const iaziApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    iaziGetCommonData: builder.query({
      query: iaziCommonDataRequestQuery,
      providesTags: iaziCommonDataRequestProvidesTags,
      transformResponse: transformResponse,
    }),
    iaziGetTextStyles: builder.query({
      query: iaziTextStylesRequestQuery,
      providesTags: ["IaziTextStyles"],
    }),
    iaziGenerateText: builder.mutation({
      query: iaziGenerateTextRequestQuery,
    }),
    iaziPrevalidation: builder.query({
      query: iaziPrevalidationRequestQuery,
      providesTags: iaziPrevalidationRequestProvidesTags,
    }),
    iaziMunicipalityInfo: builder.mutation<
      IaziMunicipalityInfoResponse,
      IaziMunicipalityInfoRequestBody
    >({
      query: iaziMunicipalityInfoRequestQuery,
    }),
    iaziAddressValidation: builder.mutation<
      IaziAddressValidationResponse,
      IaziAddressValidationRequestBody
    >({
      query: iaziAddressValidationRequestQuery,
    }),
    iaziModelReport: builder.mutation<
      IaziModelReportResponse,
      IaziModelReportRequestBody
    >({
      query: iaziModelReportRequestQuery,
    }),
    iaziLoreReport: builder.mutation<
      IaziLoreReportResponse,
      IaziLoreReportRequestBody
    >({
      query: iaziLoreReportRequestQuery,
    }),
  }),
  overrideExisting: false,
});

export const {
  useIaziGetCommonDataQuery,
  useLazyIaziGetCommonDataQuery,
  useIaziGetTextStylesQuery,
  useLazyIaziGetTextStylesQuery,
  useIaziGenerateTextMutation,
  useIaziPrevalidationQuery,
  useIaziMunicipalityInfoMutation,
  useIaziAddressValidationMutation,
  useIaziModelReportMutation,
  useIaziLoreReportMutation,
} = iaziApi;

export const iaziApiUtil = iaziApi.util;
