import { useMemo } from "react";
import { isValidDateString } from "utilities/dateTime";
import { DayPicker } from "react-day-picker";
import { getDisableMatcher } from "./helpers";
import { de } from "react-day-picker/locale";
import { DatePickerBaseProps, DateRange, LOCALE } from "./DatePicker";

interface DatePickerRangeProps extends DatePickerBaseProps {
  onChange?: (value?: DateRange) => void;
  value?: DateRange;
}

export const DatePickerRange = ({
  onChange,
  locale,
  value,
  rangeStart,
  rangeEnd,
  today,
  endMonth,
  startMonth,
}: DatePickerRangeProps) => {
  const todayDate = useMemo<Date>(
    () => isValidDateString(today) || new Date(),
    [today]
  );

  return (
    <DayPicker
      data-testid="day-picker-range"
      mode="range"
      selected={value as DateRange}
      className="date-picker-range"
      onSelect={(newDate) => {
        onChange?.(newDate as any); // TODO check types by integration
      }}
      disabled={getDisableMatcher({ rangeStart, rangeEnd, todayDate })}
      numberOfMonths={2}
      startMonth={startMonth}
      endMonth={endMonth}
      weekStartsOn={1}
      locale={locale ? LOCALE[locale] : de}
    />
  );
};
