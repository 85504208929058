import { MessageAttachFilesControl } from "./MessageAttachFilesControl";
import { MessageSendContactLinkControl } from "./MessageSendContactLinkControl";
import { MessageAddReminderControl } from "./MessageAddReminderControl";
import { type AddRemainderFormShape } from "../../add-reminder/AddReminderForm/AddReminderForm";
import { PropertyListReducedShape } from "api/entities/property/endpoints/list-reduced/types";
import { PropertyAttachment } from "@casasoft/styleguide/components/legacy/MediaChooserPropertyAttachments/helpers/propertyAttachment.model";
import type { MessageAttachment } from "api/entities/event/endpoints/create-single/types";

export type AcceptedFieldKeys = "sendContactLink" | "addReminderForm";

export type MessageMiscControlsChangeHandlers = {
  sendContactLink: (isOn: boolean) => void;
  addReminderForm: (data: AddRemainderFormShape) => void;
  attachments: (newValue: MessageAttachment[]) => void;
  propertyAttachments: (newValue: PropertyAttachment[]) => void;
};

export type MessageMiscControlsValues = {
  sendContactLink: boolean;
  addReminderForm: AddRemainderFormShape | undefined;
  attachments: MessageAttachment[];
  propertyAttachments: PropertyAttachment[];
};

interface MessageMiscControlsProps {
  onToggleFormatting?: () => void;
  onAddReminderToggle?: (isOpen: boolean) => string | undefined;
  values: MessageMiscControlsValues;
  changeHandlers: MessageMiscControlsChangeHandlers;
  selectedProperty?: PropertyListReducedShape;
}

export const MessageMiscControls = ({
  selectedProperty,
  onAddReminderToggle,
  values,
  changeHandlers,
}: MessageMiscControlsProps) => {
  return (
    <div className="tw-flex tw-items-center tw-mt-6 tw-mb-4 tw-gap-x-3 tw-gap-y-2 tw-flex-wrap">
      <MessageAttachFilesControl
        onSubmit={(newAttachments: MessageAttachment[]) =>
          changeHandlers.attachments(newAttachments)
        }
        attachments={values.attachments}
        selectedProperty={selectedProperty}
        propertyAttachments={values.propertyAttachments}
        onSubmitPropertyAttachments={changeHandlers.propertyAttachments}
      />
      <MessageAddReminderControl
        value={values.addReminderForm}
        onToggle={onAddReminderToggle}
        onSubmit={(data) => {
          changeHandlers.addReminderForm(data);
        }}
      />
      <MessageSendContactLinkControl
        value={values.sendContactLink}
        onToggle={changeHandlers.sendContactLink}
      />
    </div>
  );
};
