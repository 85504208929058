import { createQuery } from "api/createEndpoint";
import { MESSAGE_SETTINGS_API_ROOT } from "../constants";
import type { UpdateSingleUserMessageSettingsPayload } from "./types";

const query = createQuery<UpdateSingleUserMessageSettingsPayload>(
  ({ type, body }) => {
    return {
      url: `${MESSAGE_SETTINGS_API_ROOT}/${type}/${body.user}`,
      method: "PATCH",
      data: body,
    };
  }
);

export default query;
