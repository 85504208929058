import { Alert } from "@casasoft/styleguide/components/helpers-ux";
import { useTranslation } from "react-i18next";

export const MessageSettingsUpdatedAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert color={"success"}>
      <div>{t("You have successfully updated your mailserver settings.")}</div>
    </Alert>
  );
};
