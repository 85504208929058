import { CasaoneApiTagTypes, CasaoneResultDescription } from "api/types";
import {
  IaziPrevalidationRequestBody,
  IaziPrevalidationResponse,
} from "./types";

const providesTags: CasaoneResultDescription<
  IaziPrevalidationResponse,
  IaziPrevalidationRequestBody,
  CasaoneApiTagTypes
> = (result, _, arg) => {
  if (result) {
    return [{ type: "IaziPrevalidation", id: arg.propertyId }];
  }

  return ["IaziPrevalidation"];
};

export default providesTags;
