import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const MessagePendingText = () => {
  const { t } = useTranslation();

  return (
    <div>
      {t(
        "Your message is being processed. Please refresh for latest states by clicking “Send/Receive” on the"
      )}
      <Link title="Message list" to="/message/list">
        {t("Message list")}
      </Link>
      {t("or come back later.")}
    </div>
  );
};
