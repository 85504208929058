export interface Api1ErrorShape {
  /** human readable error details */
  detail: string;
  /* http status code */
  status: number;
  /* http status code title */
  title: string;
  /** w3.org spec link */
  type: string;
  /** For validation errors, there usually is a validation messages object */
  validation_messages?: Record<string, string[]>;
}

/** Identify type wheter it is an Api1ErrorShape */
export function isApi1ErrorShape(error: unknown): error is Api1ErrorShape {
  return (
    typeof error === "object" &&
    error !== null &&
    "detail" in error &&
    "status" in error &&
    "title" in error &&
    "type" in error
  );
}

export interface ApiErrorDefinition {
  /** discriminator to identify this as an ApiErrorDefinition */
  is_api_error_definition: true;

  /** Name (comes from "SerializedError") */
  name?: string;
  /** Stack trace (comes from "SerializedError") */
  stack?: string;

  /** Error code */
  code?: string;
  /* More verbose error message */
  message?: string;
  /* HTTP status code */
  status?: string | number;
  /* Optional rendered details where the key is a "field" and the value is an array of messages */
  details?: Record<string, string[]>;
  /** Optional extra details which we have now clue what shape it is */
  unknown_details?: unknown;
}

/** Identify type wheter it is an ApiErrorDefinition */
export function isApiErrorDefinition(
  error: unknown
): error is ApiErrorDefinition {
  return (
    typeof error === "object" &&
    error !== null &&
    "is_api_error_definition" in error &&
    (error as any).is_api_error_definition === true
  );
}
