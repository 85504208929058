export type NotificationColor =
  | "danger"
  | "warning"
  | "warning-100" // very light yellow
  | "info"
  | "info-100" // very light blue
  | "success"
  | "success-100"; // very light green

export const COOKIES_AVAILABLE = [
  "cs_unit_test_cookie",
  "cs_invoice_overdue",
  "cs_phishing_warning",
  "cs_release_contact-merge-modal",
  "cs_last_days_website",
  "cs_sync-button_infrastructure-card",
  "cs_maintenance_2023-11-03",
  "cs_technical_issues_warning",
  "cs_technical_issues_warning_30_1",
  "cs_message_recipients_info",
  "cs_maintenance_2024-06-17",
  "cs_message_platform_release_2025-01-15",
  "cs_message_release_2025-01-15",
  "cs_technical_issues_danger_22_1_25",
  "cs_technical_issues_warning_23_1_25",
] as const;

export type CookieType = typeof COOKIES_AVAILABLE[number];
