import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { i18n, API } from "utilities";

export interface Preset {
  [key: string]: string | string[];
}

export interface UserFilterPresetValue {
  key: string;
  label: string;
  active?: boolean;
  preset: Preset;
}

export type UserFilterPresets = {
  [key in string]?: UserFilterPresetValue[];
};

export interface AuthShape {
  profile: {
    isFetching: boolean;
    didInvalidate: boolean;
    lastUpdated?: number;
    data: Partial<
      Omit<API.FetchProfileAPIResponse, "casaoneUser"> & {
        casaoneUser: Partial<
          Omit<API.FetchProfileCasaoneUserShape, "filterPresets">
        > & {
          filterPresets: UserFilterPresets;
        };
      }
    >;
  };
}

export const updateUserFilterPresets = createAction(
  "@AUTH/UPDATE_USER_FILTERPRESETS",
  (storeName: string, payload: UserFilterPresetValue[]) => {
    return {
      payload: {
        storeName,
        payload,
      },
    };
  }
);

export const receiveProfile = createAction(
  "@AUTH/RECEIVE_PROFILE",
  (json?: API.FetchProfileAPIResponse) => {
    return {
      payload: {
        data: json,
        receivedAt: Date.now(),
      },
    };
  }
);

export type FetchProfileCBArg =
  | { status: "DONE"; data: { [key: string]: any } }
  | { status: "VALIDATION"; data: string; id: string }
  | { status: "ERR"; data: string; id: string };

export const fetchProfile = createAsyncThunk<
  boolean | void,
  { cb?: (arg: FetchProfileCBArg) => void },
  {
    rejectValue: {
      response?: Error;
      updateReceivedAt: number;
    };
  }
>("@AUTH/FETCH_PROFILE", async ({ cb }, { dispatch }) => {
  try {
    const json = await API.fetchProfileAPI();
    let hasActiveContract = false;

    if (json?.company?.contracts) {
      json.company.contracts.forEach((contract: any) => {
        if (contract.status !== "inactive") {
          hasActiveContract = true;
        }
      });
    }

    if (!hasActiveContract) {
      cb?.({
        status: "VALIDATION",
        data: i18n.t(
          "Your CASAONE contract is inactive. Please contact our support."
        ),
        id: "inactive-contract",
      });
      return false;
    }

    if (!json?.casaoneUser?.roles?.length) {
      cb?.({
        status: "VALIDATION",
        data: i18n.t(
          "Your CASAONE user is inactive. Please contact your CASAONE admin."
        ),
        id: "inactive-user",
      });
      return false;
    }

    if (json.casaoneUser) {
      dispatch(receiveProfile(json));
      cb?.({ status: "DONE", data: json });

      const lang = json.casaoneUser?.contact?.lang;
      if (lang) {
        i18n.changeLanguage(lang);
      }
      // user based theming is not enabled yet
      // if (json.casaoneUser?.themeSettings) {
      //   updateThemeSettings({
      //     theme: json.casaoneUser?.themeSettings.theme,
      //     themeColor: json.casaoneUser?.themeSettings.themeColor,
      //   });
      // }
      return true;
    }

    const error = new Error(
      "The profile failed to fetch with an unknown error"
    );
    console.error("The following response json was not handled");
    console.error(json);
    throw error;
  } catch (err) {
    const error = new Error(`Profile failed to fetch`);

    if (err instanceof Error) {
      if (err.message === "unauthorized") {
        cb?.({
          status: "VALIDATION",
          data: i18n.t("You have been logged out."),
          id: "unauthorized",
        });
      } else {
        cb?.({ status: "ERR", data: error.message, id: "unknown" });
      }
    } else {
      console.error(err);
      cb?.({ status: "ERR", data: error.message, id: "unknown" });
    }
  }
  return false;
});

export const refreshProfile = createAsyncThunk<
  void,
  (successfull: boolean) => void,
  {
    rejectValue: {
      response?: Error;
      updateReceivedAt: number;
    };
  }
>("@AUTH/REFRESH_PROFILE", (cb, { dispatch }) => {
  try {
    dispatch(
      fetchProfile({
        cb: (response) => {
          if (response.status === "DONE") {
            cb?.(true);
          } else {
            console.error("refresh profile failed");
            cb?.(false);
          }
        },
      })
    );
  } catch (err) {
    cb?.(false);
    const error = new Error(`refreshProfile failed with an unknown reason`);
    Object.assign(error, {
      error: err,
    });
  }
});

export const authErrorIdsToMessage = (errId: unknown) => {
  switch (errId) {
    case "unauthorized":
      return i18n.t("You have been logged out.");
    case "invalid-grant":
      return i18n.t("Invalid username and password combination");
    case "inactive-contract":
      return i18n.t(
        "Your CASAONE contract is inactive. Please contact our support."
      );
    case "inactive-user":
      return i18n.t(
        "Your CASAONE user is inactive. Please contact your CASAONE admin."
      );
    case "unknown-validation-error":
      return i18n.t("The form has errors. Please check your entries.");
    case "no-matching-passwords":
      return i18n.t("The confirmation Password needs to match the password");
    case "invalid-or-outdated-token":
      return i18n.t(
        "This reset password link is expired or has already been used."
      );
    case "unknown":
    default:
      return i18n.t("An error has occurred. Please try again in 5 minutes.");
  }
};
