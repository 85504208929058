import { ContactSelectValueType } from "components/contact/features/contact-select/ContactSelectContainer";
import { MessageCreateFormShape } from "../../MessageCreateForm";
import {
  AttendeeMutationShape,
  EmailContact,
} from "api/entities/event/endpoints/create-single/types";
import { AttendeeTypes } from "../../../../../../entities/event/types";

const enum EmailAttendeeTypes {
  SENDER = "SENDER",
  RECIPIENT = "RECIPIENT",
  CC = "CC",
  BCC = "BCC",
}

export type EmailAttendeeType = `${EmailAttendeeTypes}`;

export const NEW_DELIMITER = "new:";

export const getPrivate = (
  emailRecipientType: EmailAttendeeType
): boolean | undefined => {
  switch (emailRecipientType) {
    case "RECIPIENT":
      return undefined;
    case "CC":
      return false;
    case "BCC":
      return true;
  }
};

export const getAttendeeType = (emailRecipientType: EmailAttendeeType) => {
  switch (emailRecipientType) {
    case "SENDER":
      return AttendeeTypes.SENDER;
    case "RECIPIENT":
      return AttendeeTypes.RECIPIENT;
    case "CC":
    case "BCC":
      return AttendeeTypes.ATTENDEE;
  }
};

const mapContactToAttendee = (
  contact: string,
  emailRecipientType: EmailAttendeeType
): AttendeeMutationShape => ({
  contact: getContactId(contact),
  attendeeType: getAttendeeType(emailRecipientType),
  private: getPrivate(emailRecipientType),
});

export const getNewContactEmail = (contact: string): EmailContact | undefined =>
  contact.includes(NEW_DELIMITER)
    ? { email: contact.split(":")[1] }
    : undefined;

export const getContactId = (contact: string): string | EmailContact =>
  getNewContactEmail(contact) || contact;

export const getMessageAttendees = (
  data: MessageCreateFormShape,
  senderId: string | undefined,
  recipientId: string
): AttendeeMutationShape[] => {
  const cc = data.contactCC || [];
  const bcc = data.contactBCC || [];

  if (!senderId) {
    throw new Error("Sender user id not found");
  }

  return [
    mapContactToAttendee(recipientId, "RECIPIENT"),
    mapContactToAttendee(senderId, "SENDER"),
    ...cc.map((contact) => mapContactToAttendee(contact.value, "CC")),
    ...bcc.map((contact) => mapContactToAttendee(contact.value, "BCC")),
  ];
};

export const getReminderAttendees = (
  senderId: string,
  recipientId: string
): AttendeeMutationShape[] => {
  return [
    mapContactToAttendee(senderId, "SENDER"),
    mapContactToAttendee(recipientId, "RECIPIENT"),
  ];
};

export const contactListShouldReload = (
  contact: readonly ContactSelectValueType[] = [],
  contactCC: readonly ContactSelectValueType[] = [],
  contactBCC: readonly ContactSelectValueType[] = []
): boolean =>
  [...contact, ...contactCC, ...contactBCC].some((c) =>
    c.value.includes(NEW_DELIMITER)
  );
