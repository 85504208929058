import { datadogRum } from "@datadog/browser-rum";
import { history } from "config/history";
import { DEFAULT_USER_LANG } from "redux/auth/selectors";
import store from "redux/store";

function logOut() {
  const state = store.getState();
  const userLang =
    state.auth.profile.data.casaoneUser?.contact?.lang || DEFAULT_USER_LANG;

  datadogRum.clearUser();

  history.push(`/public/logout/${userLang}`);
}

export default logOut;
