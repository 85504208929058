import { DateRange as DateRangeType } from "react-day-picker";
import { enGB, de, frCH, itCH, es } from "react-day-picker/locale";
import { useMemo } from "react";
import { DatePickerSingle } from "./DatePickerSingle";
import { DatePickerRange } from "./DatePickerRange";

export const LOCALE = {
  en: enGB, // en-gb
  de: de, // de-ch
  fr: frCH, // fr-ch
  it: itCH, // it-ch
  es: es,
};

export type UserLocale = keyof typeof LOCALE;

export interface DateRange extends DateRangeType {}

export interface DatePickerBaseProps {
  locale?: UserLocale; // userLanguage
  rangeStart?: string;
  rangeEnd?: string;
  today?: string;
  endMonth: Date;
  startMonth: Date;
}

export interface DatePickerProps
  extends Omit<DatePickerBaseProps, "endMonth" | "startMonth"> {
  showTodayButton?: boolean;
  onChange?: (value?: string | DateRange) => void;
  value?: string | DateRange;
  isRangeMode?: boolean;
}

export const DatePicker = ({
  onChange,
  showTodayButton,
  locale = "de",
  value,
  rangeStart,
  rangeEnd,
  today,
  isRangeMode,
}: DatePickerProps) => {
  const startMonth = useMemo<Date>(() => new Date(1900, 1), []);
  const endMonth = useMemo<Date>(() => new Date(2150, 1), []);

  return isRangeMode ? (
    <DatePickerRange
      today={today}
      onChange={onChange}
      locale={locale}
      value={value as DateRange}
      rangeStart={rangeStart}
      rangeEnd={rangeEnd}
      startMonth={startMonth}
      endMonth={endMonth}
    />
  ) : (
    <DatePickerSingle
      today={today}
      onChange={onChange}
      showTodayButton={showTodayButton}
      locale={locale}
      value={value as string}
      rangeStart={rangeStart}
      rangeEnd={rangeEnd}
      startMonth={startMonth}
      endMonth={endMonth}
    />
  );
};
