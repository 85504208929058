import {
  clearAuthTokens,
  fetchAuthTokens,
  getAuthTokens,
  shouldRefetchAuthToken,
  waitForPreviousTokenRefresh,
} from "utilities/auth";
import "config/reduxRestResource";
import { history } from "config/history";
import { InternalAxiosRequestConfig } from "axios";
import axiosInstance from "./axiosInstance";
import { datadogRum } from "@datadog/browser-rum";

async function authTokensRequestInterceptor(
  request: InternalAxiosRequestConfig<any>
) {
  await waitForPreviousTokenRefresh();
  const activeTokens = getAuthTokens();

  if (!activeTokens.accessToken) {
    // reset fetching state
    window.__isFetchingAuthToken = false;

    datadogRum.clearUser();

    // throw
    throw new Error("No existing tokens found that can be used");
  }

  // if accesstokenExpire has expired - refreshtoken first
  if (shouldRefetchAuthToken()) {
    const successfullyFetchedAuthTokens = await fetchAuthTokens({
      refreshToken: activeTokens.refreshToken,
    });
    if (successfullyFetchedAuthTokens !== true) {
      history.push(`/public/error/?errorId=unknown`);
      await clearAuthTokens();
      throw new Error(
        "Refresh token supposibly was still valid, but fetching a new auth token failed anyways"
      );
    }

    if (request.headers?.Authorization) {
      request.headers.Authorization =
        axiosInstance.defaults.headers.common.Authorization;
    }
  }

  return request;
}

export default authTokensRequestInterceptor;
