import BaseState from "entities/baseState";
import { ContactShape } from "entities/contact/types";
import { DateTime } from "api/entities";
import { EventShapeEmbedded } from "api/entities/event/endpoints/single/types";

export enum AttendeeTypes {
  SENDER = "sender",
  RECIPIENT = "recipient",
  ATTENDEE = "attendee",
}

export type AttendeeType = `${AttendeeTypes}`;

export type Attendee = {
  id: string;
  rank: number;
  attendeeType: AttendeeType;
  private?: boolean;
  created: DateTime;
  updated: DateTime;
  _embedded: {
    contact: ContactShape;
  };
};

export interface EventShape {
  created: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
  id: string;
  importId: string;
  customSource: string;
  eventTrigger: string;
  threadUpdated?: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
  status: string;
  doneReason: string;
  type: string;
  transcript?:
    | {
        raw_header?: string;
        raw_content?: string;
        attachment_count?: number;
        log?: string[];
        smtpResponse?: {
          detail?: string;
        };
        smtp?: {
          fromEmail?: string;
          fromName?: string;
        };
      }
    | string;
  source?: string;
  sourceId?: string;
  start: DateTime;
  end?: DateTime;
  reminderDate: DateTime | null;
  txt: string;
  html: string;
  gatewayPublisher: string;
  subject: string;
  lang: string;
  private: boolean;
  offerAmount?: string;
  requestedRelationType: string;
  _embedded?: EventShapeEmbedded;
}

export default interface EventState extends BaseState {
  item?: EventShape;
  items: EventShape[];
}
