import type { CasaoneBaseQueryArgs } from "api/types";
import { IaziLoreReportRequestBody } from "./types";

const query = (body: IaziLoreReportRequestBody): CasaoneBaseQueryArgs => ({
  url: "api/v1/iazi-lore-report",
  method: "POST",
  data: { ...body },
});

export default query;
