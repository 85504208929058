import { Spinner } from "@casasoft/styleguide/components/helpers-ux";
import { useEffect } from "react";
import { useLocation } from "react-router";

export const MESSAGE_SETTING_OAUTH_STORAGE_KEY = "co_oauth_cb_vals";

function OAuthReturn() {
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);

    const stateParam = params.get("state") || "{}";

    window.localStorage.setItem(
      MESSAGE_SETTING_OAUTH_STORAGE_KEY,
      JSON.stringify(JSON.parse(stateParam))
    );
    window.close();
  }, [search]);

  return (
    <div className="tw-h-full tw-w-full tw-relative spinner-fixture">
      <Spinner />
    </div>
  );
}

export default OAuthReturn;
