import { createReducer } from "@reduxjs/toolkit";
import { API } from "utilities";
import {
  AuthShape,
  receiveProfile,
  updateUserFilterPresets,
  UserFilterPresets,
} from "./actions";

const initialState: AuthShape = {
  profile: {
    isFetching: false,
    didInvalidate: true,
    data: {},
  },
};

const authReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(receiveProfile, (state, action) => {
      const copyPayload: API.FetchProfileAPIResponse = JSON.parse(
        JSON.stringify(action.payload.data || {})
      );

      // fixes MySQL returning an array bug when filterPresets are empty
      // also allows login when filterPresets is a string and clears it to {} on filter change
      const newFilterPresetsFromDb: UserFilterPresets | [] =
        copyPayload.casaoneUser?.filterPresets || {};

      let newFilterPresets: UserFilterPresets;
      if (Array.isArray(newFilterPresetsFromDb)) {
        newFilterPresets = {};
      } else if (typeof newFilterPresetsFromDb === "string") {
        newFilterPresets = {};
      } else {
        newFilterPresets = newFilterPresetsFromDb;
      }

      if (
        copyPayload?.casaoneUser?.filterPresets &&
        typeof copyPayload?.casaoneUser?.filterPresets === "string"
      ) {
        // and for the preset fields aswell
        Object.entries(newFilterPresets).forEach((entry) => {
          entry[1]?.forEach((preset, i) => {
            const currFilterPreset = newFilterPresets[entry[0]];
            if (!currFilterPreset) {
              throw new Error("currFilterPreset is undefined");
            }

            if (Array.isArray(preset.preset)) {
              // console.log(
              //   "FOUND A CULPRIT",
              //   newFilterPresets[entry[0]][i],
              // );

              currFilterPreset[i].preset = {};
            }

            // this is to migrate from old filter values that are still stored as strings instead of an array of strings
            Object.entries(currFilterPreset[i].preset).forEach((presetVal) => {
              if (!Array.isArray(presetVal[1])) {
                currFilterPreset[i].preset[presetVal[0]] = [presetVal[1]];
              }
              // delete arrays with falsy values
              if (!currFilterPreset[i].preset[presetVal[0]][0]) {
                delete currFilterPreset[i].preset[presetVal[0]];
              }
            });
          });
        });
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          isFetching: false,
          didInvalidate: false,
          data: {
            ...copyPayload,
            casaoneUser: {
              ...(copyPayload.casaoneUser || {}),
              filterPresets: newFilterPresets,
            },
          },
          lastUpdated: action.payload.receivedAt,
        },
      };
    })
    .addCase(updateUserFilterPresets, (state, action) => {
      const casaOneUser = state.profile.data.casaoneUser;
      if (casaOneUser) {
        return {
          ...state,
          profile: {
            ...state.profile,
            data: {
              ...state.profile.data,
              casaoneUser: {
                ...casaOneUser,
                filterPresets: {
                  ...(state.profile.data.casaoneUser?.filterPresets || {}),
                  [action.payload.storeName]: action.payload.payload,
                },
              },
            },
          },
        };
      }
      return state;
    });
});

export { authReducer };
