import Button from "@casasoft/styleguide/components/forms/Button";
import { PresetField } from "./FilterForm";
import { useEffect, useState } from "react";

interface FilterFormPresetProps {
  preset: PresetField;
  onOpenClick: () => void;
}

const DEFAULT_RENDERER = async (val: string[]) => {
  return val.join(", ");
};

export const FilterFormPreset = ({
  onOpenClick,
  preset,
}: FilterFormPresetProps) => {
  const { value, label, renderer = DEFAULT_RENDERER } = preset;
  const [renderedValue, setRenderedValue] = useState<string | undefined>();

  useEffect(() => {
    const valueList = typeof value === "string" ? [value] : value || [];
    setRenderedValue(valueList.join(", "));

    (async () => {
      const result = await renderer(valueList);
      if (result) {
        setRenderedValue(result);
      }
    })();
    // IMPORTANT: do not add dependecies, we want to run this effect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return renderedValue ? (
    <div className="tw-mr-3">
      <Button onClick={onOpenClick} isLight isOutline isSmall>
        {label}: {renderedValue}
      </Button>
    </div>
  ) : null;
};
