import { CasaoneResultDescription } from "api/types";
import { SendMessageRequestPayload, SendMessageResponse } from "./types";

const invalidatesTags: CasaoneResultDescription<
  SendMessageResponse,
  SendMessageRequestPayload
> = () => {
  return ["Event", "Contact"];
};

export default invalidatesTags;
