import { Preset } from "components/miscellaneous/FilterForm";
import { UserFilterPresets } from "redux/auth";
import { useRef } from "react";
import { useAppSelector } from "redux/hooks";

// get activeFilterpreset and fill it with the current active form
const PrepareActiveFilterPreset = (
  /** either the already grabbed  */
  usersActiveFilterPreset?: NonNullable<UserFilterPresets[string]>[number],
  filterFormOptions?: Omit<Preset, "key">
) => {
  // gets the currently active filter preset and its values from the store
  if (usersActiveFilterPreset) {
    const activeFilterPreset: Preset = {
      preset: {},
      label: usersActiveFilterPreset.label,
      key: usersActiveFilterPreset.key,
    };
    if (filterFormOptions?.preset) {
      Object.entries(filterFormOptions.preset).forEach((presetItemDuplets) => {
        let resultItem = presetItemDuplets[1];
        if (usersActiveFilterPreset) {
          const foundValue =
            usersActiveFilterPreset.preset[presetItemDuplets[0]];
          if (foundValue) {
            resultItem = {
              ...resultItem,
              value: foundValue,
            };
          }
        }
        activeFilterPreset.preset[presetItemDuplets[0]] = resultItem;
      });
    }
    return activeFilterPreset;
  }
};

function usePreparedActiveFilterPreset(
  storeName: string,
  filterFormOptions?: Omit<Preset, "key">
) {
  const { auth } = useAppSelector((state) => ({
    auth: state.auth,
  }));

  const emptyArrayRef = useRef<[]>([]); // safety -> so filterPresets will fall back to a ref array instead of a new variable for every render

  const filterPresets =
    auth.profile.data.casaoneUser?.filterPresets[storeName] ||
    emptyArrayRef.current;

  const activePreset = filterPresets.find((presets) => presets.active);
  return PrepareActiveFilterPreset(activePreset, filterFormOptions);
}

export default usePreparedActiveFilterPreset;
