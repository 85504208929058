import { CasaoneBaseQueryArgs } from "api/types";
import { FetchMessageRequestPayload } from "./types";

function query(data: FetchMessageRequestPayload): CasaoneBaseQueryArgs {
  return {
    url: `api/v1/message/fetch`,
    method: "POST",
    data,
  };
}

export default query;
