import { useTranslation } from "react-i18next";
import { type AlertItem } from "./models";
import {
  CookieType,
  NotificationColor,
} from "@casasoft/styleguide/components/notification/models";

const ACTIVE_UNTIL = new Date("2025-02-03T06:00:00");

export const useMaintenanceWarning = ({
  component,
  colorType = "info",
  cookieId = "cs_message_platform_release_2025-01-15",
}: {
  component?: React.ReactElement;
  colorType?: NotificationColor;
  cookieId?: CookieType;
} = {}) => {
  const { t } = useTranslation();

  const maintenanceWarning: AlertItem | undefined =
    ACTIVE_UNTIL > new Date()
      ? {
          colorType,
          cookieId,
          text: component || `${t("cap--821")}`,
        }
      : undefined;

  return {
    maintenanceWarning,
  };
};
