import type { CasaoneBaseQueryArgs } from "api/types";
import { IaziModelReportRequestBody } from "./types";

const query = (
  body: IaziModelReportRequestBody,
  isSingleFamilyProperty = false
): CasaoneBaseQueryArgs => ({
  url: isSingleFamilyProperty
    ? "api/v1/iazi-service-single-family"
    : "api/v1/iazi-service",
  method: "POST",
  data: { ...body },
});

export default query;
