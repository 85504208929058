import { dynamicContactResource } from "entities/contact/dynamicStore";
import resourceHelper from "utilities/resource";

interface CasaoneContactShape {
  id?: string;
  legalForm?: string;
  legalName?: string;
  addition?: string;
  phone?: string;
  fax?: string;
  mobile?: string;
  email?: string;
  websiteUrl?: string;
  uid?: string;
  slug?: string;
  address?: string;
}
interface CasaoneContactAddressShape {
  lat?: string;
  lng?: string;
  street?: string;
  streetNumber?: string;
  region?: string;
  postOfficeBoxNumber?: string;
  locality?: string;
  postalCode?: string;
  country?: string;
}

export function updateCompany(data: CasaoneContactShape) {
  dynamicContactResource
    // eslint-disable-next-line deprecation/deprecation
    .fetchList({
      filter: [
        {
          type: "eq",
          field: "sourceId",
          value: data.id,
        },
      ],
    })
    .then((response) => {
      if (response.length) {
        // eslint-disable-next-line deprecation/deprecation
        dynamicContactResource.updateItem(response[0].id, {
          legalForm: data.legalForm,
          displayName: data.legalName,
          addition: data.addition,
          phone: data.phone,
          fax: data.fax,
          mobile: data.mobile,
          email: data.email,
          websiteUrl: data.websiteUrl,
          uid: data.uid,
          source: "casaauth",
          sourceId: data.id,
          address: data.address,
        });
        return response[0].id;
      } else {
        createCompany(data);
      }
    });
}
export function createCompany(data: CasaoneContactShape) {
  // eslint-disable-next-line deprecation/deprecation
  return dynamicContactResource.createItem({
    legalForm: data.legalForm ? data.legalForm : "",
    displayName: data.legalName ? data.legalName : "",
    addition: data.addition ? data.addition : "",
    phone: data.phone ? data.phone : "",
    fax: data.fax ? data.fax : "",
    mobile: data.mobile ? data.mobile : "",
    email: data.email ? data.email : "",
    websiteUrl: data.websiteUrl ? data.websiteUrl : "",
    uid: data.uid ? data.uid : "",
    source: "casaauth",
    sourceId: data.id ? data.id : "",
    address: data.address,
  });
}
export function updateCompanyAddress(
  companyId: string,
  legalName: string,
  data: any,
  fullData: any
) {
  dynamicContactResource
    // eslint-disable-next-line deprecation/deprecation
    .fetchList({
      filter: [
        {
          type: "eq",
          field: "sourceId",
          value: companyId,
        },
      ],
    })
    .then((response) => {
      if (response?.length) {
        if (response[0]?._embedded?.address?.id) {
          data.id = response[0]._embedded.address.id;
          // eslint-disable-next-line deprecation/deprecation
          resourceHelper.updateItem("address", data);
        } else {
          createCompanyAddress(fullData, companyId);
        }
      } else {
        let companyData = {
          id: companyId,
          displayName: legalName,
        };
        createCompanyAndAddress(fullData, companyData);
      }
    });
}
export function createCompanyAddress(
  data: CasaoneContactAddressShape,
  companyId: string
) {
  let updateCompanyData = {
    id: companyId,
    address: "",
  };
  let newAddress = {
    lat: data.lat,
    lng: data.lng,
    street: data.street,
    street_number: data.streetNumber,
    region: data.region,
    post_office_box_number: data.postOfficeBoxNumber,
    locality: data.locality,
    postal_code: data.postalCode,
    country: data.country,
  };

  // eslint-disable-next-line deprecation/deprecation
  resourceHelper.createItem("address", newAddress).then((response: any) => {
    if (response.code === 201) {
      updateCompanyData.address = response.body.id;
      updateCompany(updateCompanyData);
    }
  });
}
export function createCompanyAndAddress(addressData: any, companyData: any) {
  delete addressData.id;
  delete addressData.created;
  delete addressData.updated;

  // eslint-disable-next-line deprecation/deprecation
  resourceHelper.createItem("address", addressData).then((response: any) => {
    if (response.code === 201) {
      companyData.address = response.body.id;
      createCompany(companyData);
    }
  });
}
