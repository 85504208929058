import type { MessageAttachment } from "api/entities/event/endpoints/create-single/types";
import { AddRemainderFormShape } from "components/message/features/add-reminder/AddReminderForm/AddReminderForm";
import { getReminderAttendees } from "./attendeesManager";

export const getCleanedUpMedia = (
  attachments: MessageAttachment[]
): MessageAttachment[] => {
  if (!attachments) return [];
  return attachments
    .filter((a) => a.media)
    .map((attachment) => {
      return {
        ...attachment,
        media: attachment.media.id as string,
      };
    });
};

export const transformReminderToPayload = (
  data: AddRemainderFormShape | undefined,
  senderUserId: string | undefined
) => {
  if (!data || !data.user) return undefined;

  const { subject, date, hour, notes, user } = data;

  if (!senderUserId) {
    throw new Error("Sender user id not found");
  }

  return {
    subject: subject,
    html: notes,
    start: date ? `${date} ${hour || "00:00"}` : date,
    attendees: getReminderAttendees(senderUserId, user),
  };
};

export const getEventPhase = (phase: string | undefined) => {
  if (!phase) return undefined;
  return { phase };
};
