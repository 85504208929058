import { AttendeeMutationShape } from "api/entities/event/endpoints/create-single/types";
import { Attendee, AttendeeTypes } from "entities/event/types";

interface GetAttendeesProps {
  sender?: string | null;
  recipient?: string | null;
}

interface GetAttendeesPayloadProps {
  dirtyData: GetAttendeesProps;
  data: GetAttendeesProps;
}

export const getAttendeesUpdatePayload = ({
  dirtyData,
  data,
}: GetAttendeesPayloadProps) => {
  const { sender, recipient } = dirtyData;

  const isToDelete = sender === null || recipient === null;
  const isToUpdate = sender || recipient;

  if (!isToDelete && !isToUpdate) {
    return undefined;
  }

  const attendees: AttendeeMutationShape[] = [];

  if (data.sender && dirtyData.sender !== null) {
    attendees.push({
      attendeeType: AttendeeTypes.SENDER,
      contact: dirtyData.sender || data.sender,
    });
  }
  if (data.recipient && dirtyData.recipient !== null) {
    attendees.push({
      attendeeType: AttendeeTypes.RECIPIENT,
      contact: dirtyData.recipient || data.recipient,
    });
  }

  return attendees;
};

export const getAttendeesCreatePayload = ({
  sender,
  recipient,
}: GetAttendeesProps) => {
  const attendees = [];
  if (sender) {
    attendees.push({
      attendeeType: AttendeeTypes.SENDER,
      contact: sender,
    });
  }
  if (recipient) {
    attendees.push({
      attendeeType: AttendeeTypes.RECIPIENT,
      contact: recipient,
    });
  }

  return attendees;
};

export const getAttendeeData = (attendees?: Attendee[]) => {
  return {
    sender: attendees?.find(
      (attendee) => attendee.attendeeType === AttendeeTypes.SENDER
    ),
    recipient: attendees?.find(
      (attendee) => attendee.attendeeType === AttendeeTypes.RECIPIENT
    ),
    attendees:
      attendees?.filter(
        (attendee) => attendee.attendeeType === AttendeeTypes.ATTENDEE
      ) || [],
  };
};
