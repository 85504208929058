/* eslint-disable no-debugger */
import { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerPlus,
  faLightbulbOn,
  faTag,
} from "@fortawesome/pro-light-svg-icons";
import MediaChooser from "@casasoft/styleguide/components/legacy/MediaChooser";
import MediaChooserSelectedItems from "@casasoft/styleguide/components/legacy/MediaChooserSelectedItems";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import resourceHelper from "../../utilities/resource";
import Config from "config";
import { Media } from "utilities";
import { RootState } from "redux/store";
import { FilterItem, OrderByItem } from "utilities/queryBuilder";
import MediaState from "entities/media/types";
import { getAuthTokens } from "utilities/auth";
import { Accept } from "react-dropzone";
import { axiosInstance } from "utilities/axios";
import { MediaShape } from "entities/mediaChooser/types";
import { MediaChooserPropertyAttachmentsProps } from "@casasoft/styleguide/components/legacy/MediaChooserPropertyAttachments/MediaChooserPropertyAttachments";

// TODO: snc needs doubleckeck .... type this corectly?
type tagShape = any;

interface stateShape {
  quickAddModal?: any;
  orderBy?: OrderByItem[];
  filter?: FilterItem[];
  working?: boolean;
  mediaTags?: tagShape[];
  commonTags?: tagShape[];
  suggestedTags?: tagShape[];
  selectedItems?: [];
  controlMediaTags?: tagShape[];
  allTags?: tagShape[];
}

interface propsShape {
  actions: any;
  activeTab: string;
  authStore: any;
  mediaStore: MediaState;
  mediaTypeFilter: string;
  onSelectionChange?: (medias: any) => void;
  select: string;
  suggestedTags: tagShape[];
  t: (value: string, options?: any) => string;
  initialSelectedItems?: MediaShape[];
  propertyTabProps?: MediaChooserPropertyAttachmentsProps;
}

class MediaChooserContainer extends Component<any> {
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const resultState: stateShape = {};
    if (nextProps.mediaStore.isFetchingItem !== prevState.working) {
      resultState.working = nextProps.mediaStore.isFetchingItem;
    }
    if (nextProps.mediaStore.isUpdating !== prevState.working) {
      resultState.working = nextProps.mediaStore.isUpdating;
    }

    if (Object.keys(resultState).length === 0) {
      return null;
    }
    return resultState;
  }

  state: stateShape;
  props!: propsShape;

  static propTypes = {
    suggestedTags: PropTypes.array,
    mediaTypeFilter: PropTypes.string,
    select: PropTypes.string,
    activeTab: PropTypes.string,
    mediaStore: PropTypes.object,
    authStore: PropTypes.object.isRequired,
    onSelectionChange: PropTypes.func,
    // match: PropTypes.object,
  };

  static defaultProps = {
    suggestedTags: [],
    mediaTypeFilter: "",
    select: "multiple", // multiple none single
    activeTab: "list",
    mediaStore: {},
    // match: {
    //   url: '',
    // },
  };

  constructor(props: any, context: any) {
    super(props, context);

    this.state = {
      quickAddModal: null,
      orderBy: [
        {
          type: "field",
          field: "created",
          direction: "desc",
        },
      ],
      filter: [],
      working: false,
      mediaTags: [],
      commonTags: [],
      suggestedTags: [],
    };
  }

  __fetchTags() {
    resourceHelper.fetchList(
      "medias",
      [],
      [
        {
          type: "field",
          field: "created",
          direction: "desc",
        },
      ],
      this.props.mediaStore?.lastQuery?.pageSize || 10,
      true,
      null,
      {},
      this.props.mediaStore?.lastQuery?.page
    );
    axiosInstance
      .get(`${Config.apiUrl}/${Config.customerKey}/get-distinct-media-tags`)
      .then((response) => {
        if (response.status === 500) {
          throw Error(`${response.status} ${response.data.detail}`);
        }
        return response;
      })
      .then((response) => {
        // response
        const controlMediaTags = response.data.mediaTags
          .map((x: any) => {
            return {
              value: x.name,
              label: x.name,
              type: <FontAwesomeIcon icon={faTag} fixedWidth />,
            };
          })
          .sort((a: any, b: any) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });
        this.setState(
          {
            controlMediaTags,
            mediaTags: controlMediaTags,
          },
          () => {
            if (this.state.selectedItems) {
              this.__setTags(this.state.selectedItems);
            }
          }
        );
      });
  }

  componentDidMount() {
    this.__fetchMedias();
    this.__fetchTags();
  }

  __handleNewMediaUploaded() {
    this.__fetchMedias(null, true);
    return true;
  }

  __getAcceptedMimeTypes(mediaTypeFilter: string): Accept {
    const acceptedMimeTypesImages = {
      // note: DONT do the following. We will later use the mime-types from the object keys in a filter query -> so "*" would break that query
      // "image/*": [".png", ".jpg", ".jpeg", ".svg", ".tiff", ".tif"]

      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/tiff": [".tiff", ".tif"],
      "image/svg": [".svg"],
      "image/svg%2Bxml": [".svg"],
      "image/svg+xml": [".svg"],
    };
    const acceptedMimeTypesDocuments = {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.oasis.opendocument.text": [".odt"],
      "application/msword": [".doc", ".dot"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
      "application/vnd.ms-excel": [".xls", ".xlt", ".xla"],
      "application/pdf": [".pdf"],

      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/tiff": [".tiff", ".tif"],
    };

    if (mediaTypeFilter === "image") {
      return acceptedMimeTypesImages;
    }
    if (mediaTypeFilter === "document") {
      return acceptedMimeTypesDocuments;
    }

    // as default allow image and document
    return { ...acceptedMimeTypesImages, ...acceptedMimeTypesDocuments };
  }

  __fetchMedias(mediaQuery: any = null, force = false) {
    const curState = this.state;
    const filter = curState.filter ? [...curState.filter] : [];
    let pageSize = 40;
    const acceptedMimeTypes = Object.keys(
      this.__getAcceptedMimeTypes(this.props.mediaTypeFilter)
    );
    if (acceptedMimeTypes.length > 0) {
      filter.push({
        type: "in",
        field: "mimeType",
        where: "and",
        values: acceptedMimeTypes,
      });
    }

    if (mediaQuery) {
      if (mediaQuery.s) {
        filter.push({
          type: "search",
          value: mediaQuery.s,
        });
      }
      if (mediaQuery.tags) {
        filter.push({
          type: "leftjoin",
          field: "tags",
          alias: "tags",
        });
        filter.push({
          type: "in",
          field: "name",
          alias: "tags",
          values: mediaQuery.tags.split(","),
        });
      }
      if (mediaQuery.page) {
        pageSize *= mediaQuery.page;
      }
    }

    return new Promise<any>((resolve) => {
      resourceHelper
        .fetchList(
          "mediaChoosers",
          filter,
          curState.orderBy ? [...curState.orderBy] : [],
          pageSize,
          force
        )
        .then((response: any) => {
          if (this.state.selectedItems) {
            const selectedItemsIds = this.state.selectedItems.map(
              (item: any) => item.id
            );
            const newSelectedItems = response.items.filter((item: any) => {
              return selectedItemsIds.includes(item.id);
            });
            this.setState({ selectedItems: newSelectedItems });
          }
          this.setState({
            loading: false,
          });
          resolve(response);
        });
    });
  }

  __getUniqueTags(medias: any) {
    const uniqueTags: { name: string; count: number }[] = [];
    medias.forEach((media: any) => {
      if (media._embedded && media._embedded.tags) {
        media._embedded.tags.forEach((tag: any) => {
          const index = uniqueTags.findIndex((item) => item.name === tag.name);
          if (index === -1 && tag.name) {
            uniqueTags.push({
              name: tag.name,
              count: 1,
            });
          } else if (tag.name && uniqueTags[index]) {
            uniqueTags[index].count += 1;
          }
        });
      }
    });
    return uniqueTags;
  }

  __setTags(medias: any) {
    return new Promise<void>((resolve) => {
      const mediaTags = this.__getUniqueTags(medias);
      const commonTags = mediaTags
        .filter((tag) => tag.count === medias.length)
        .map((tag) => {
          return {
            value: tag.name,
            label: tag.name,
            type: "common",
          };
        });
      let filteredMediaTags: tagShape[] = [];
      const givenSuggestedTags = this.__getUniqueTags(medias)
        .filter((tag) => tag.count < medias.length)
        .map((tag) => {
          return {
            value: tag.name,
            label: tag.name,
            type: <FontAwesomeIcon icon={faLayerPlus} fixedWidth />,
          };
        });
      if (this.state.controlMediaTags) {
        filteredMediaTags = this.state.controlMediaTags
          .filter(
            (tag) =>
              commonTags.findIndex(
                (commonTag) => commonTag.value === tag.value
              ) === -1
          )
          .filter(
            (tag) =>
              givenSuggestedTags.findIndex(
                (givenSuggestedTag) => givenSuggestedTag.value === tag.value
              ) === -1
          );
      }

      // adding some additional tags
      const suggestedTags = this.props.suggestedTags
        .map((tag) => {
          return {
            value: tag,
            label: tag,
            type: <FontAwesomeIcon icon={faLightbulbOn} fixedWidth />,
          };
        })
        .sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        })
        .filter(
          (tag) =>
            commonTags.findIndex(
              (commonTag) => commonTag.value === tag.value
            ) === -1
        )
        .filter(
          (tag) =>
            filteredMediaTags.findIndex(
              (filteredMediaTag) => filteredMediaTag.value === tag.value
            ) === -1
        )
        .filter(
          (tag) =>
            givenSuggestedTags.findIndex(
              (givenSuggestedTag) => givenSuggestedTag.value === tag.value
            ) === -1
        )
        .concat(givenSuggestedTags);
      const allTags = [...suggestedTags, ...commonTags, ...filteredMediaTags];
      this.setState(
        {
          // mediaTags,
          suggestedTags,
          commonTags,
          allTags,
        },
        () => {
          resolve();
        }
      );
    });
  }

  __setSuggestedTags(medias: any) {
    // given tags
    const givenSuggestedTags = this.__getUniqueTags(medias)
      .filter((tag) => tag.count < medias.length)
      .map((tag) => {
        return {
          value: tag.name,
          label: tag.name,
          type: <FontAwesomeIcon icon={faLayerPlus} fixedWidth />,
        };
      });
    // adding some additional tags
    const additionalSuggestedTags = this.props.suggestedTags.map((tag) => {
      return {
        value: tag,
        label: tag,
        type: <FontAwesomeIcon icon={faLightbulbOn} fixedWidth />,
      };
    });
    const finalTags = [...givenSuggestedTags, ...additionalSuggestedTags].sort(
      (a, b) => {
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
        return 0;
      }
    );
    return finalTags;
  }

  render() {
    let postAuthorization: string | null = null;
    if (getAuthTokens().accessToken) {
      postAuthorization = `Bearer ${getAuthTokens().accessToken}`;
    }

    return (
      <div
        style={
          this.state.working
            ? {
                opacity: 0.5,
                pointerEvents: "none",
              }
            : {}
        }
      >
        <MediaChooser
          // active Item edit form
          accept={this.__getAcceptedMimeTypes(this.props.mediaTypeFilter)}
          mediaTypeFilter={this.props.mediaTypeFilter}
          onError={(fileRejections: []) => {
            // this errors are expected and a message for the user is shown -> no need to log
            const expectedErrors = [
              "file-invalid-type",
              "invalid-file-type",
              "image-too-large",
              "too-many-files",
            ];
            let criticalErrors = false;
            // check if there are some critical errors we should log
            fileRejections.forEach((fileObject: { errors: []; file: any }) => {
              if (fileObject?.errors?.length) {
                fileObject.errors.forEach(
                  (error: { code: string; message: string }) => {
                    if (!expectedErrors.includes(error.code)) {
                      criticalErrors = true;
                    }
                  }
                );
              }
            });
            if (criticalErrors) {
              const error = new Error("Media upload rejections");
              // add all needed information for further investigations if there are some errors.
              Object.assign(error, {
                upload: `${JSON.stringify(
                  fileRejections.map((fileObject: any) => ({
                    lastModified: fileObject.file.lastModified,
                    lastModifiedDate: fileObject.file.lastModifiedDate,
                    name: fileObject.file.name,
                    size: fileObject.file.size,
                    type: fileObject.file.type,
                    errors: fileObject.errors,
                  }))
                )}`,
              });
            }
          }}
          onSetSelectedItems={(items: any) => {
            this.setState({ selectedItems: items });
          }}
          initialSelectedItems={this.props.initialSelectedItems}
          selectedItemsNode={
            <MediaChooserSelectedItems
              isClearable={false}
              medias={this.state.selectedItems}
              mediasCommonTags={this.state.commonTags as any}
              mediasSuggestedTags={this.state.suggestedTags as any}
              onSelectedItemsChange={(items) => {
                return new Promise((resolve) => {
                  this.setState(
                    {
                      selectedItems: items,
                    },
                    () => resolve(this.__setTags(items))
                  );
                });
              }}
              onSelectedItemsSave={() => {
                this.__fetchMedias(null, true);
              }}
            />
          }
          onSelectionChange={(medias: any) => {
            this.setState(
              {
                selectedItems: medias,
              },
              () => this.__setTags(medias)
            );
            this.props.onSelectionChange?.(medias);
          }}
          select={this.props.select}
          activeTab={this.props.activeTab}
          postUrl={`${Config.apiUrl}/${Config.customerKey}/upload`}
          postAuthorization={postAuthorization || undefined}
          fetchMedias={(query: any) => {
            return this.__fetchMedias(query);
          }}
          mediaStore={this.props.mediaStore}
          mediaUploaded={(newMedia: any) => {
            this.__handleNewMediaUploaded();
          }}
          thumbnailUrl={(media: any) => {
            return Media.mediaThumbnail(media, "sqmd");
          }}
          propertyTabProps={this.props.propertyTabProps}
        />
      </div>
    );
  }
}

const translated = withTranslation()(MediaChooserContainer);

export default connect((state: RootState) => {
  return {
    mediaStore: state.mediaChoosers,
    authStore: state.auth,
  };
})(translated);
