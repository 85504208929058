import type { EmailContact } from "api/entities/event/endpoints/create-single/types";
import { getContactId, getNewContactEmail } from "./attendeesManager";
import { getContactAccessUrl } from "./contactAccessLinkUrl";
import { useTranslation } from "react-i18next";
import { type MessageCreateFormShape } from "../../MessageCreateForm";

export const useMessageContactManager = () => {
  const { t } = useTranslation();

  const getContactQueryData = ({
    contactString,
    recipientsLang,
    formData,
  }: {
    contactString: string;
    recipientsLang: string;
    formData: MessageCreateFormShape;
  }) => {
    const contactId: string | EmailContact = getContactId(contactString);
    const contactEmail = getNewContactEmail(contactString);
    const contactFull = formData.contact?.find(
      (c) => c.value === contactId
    )?.contact;
    const contactLang = contactFull?.lang || recipientsLang;

    const contactAccessUrls = getContactAccessUrl({
      sendContactLink: formData.sendContactLink,
      language: contactLang,
      label: t("Edit my profile", { lng: contactLang }),
    });

    return {
      contactEmail,
      contactFull,
      contactLang,
      contactAccessUrls,
    };
  };

  return { getContactQueryData };
};
