import {
  DatePickerFieldElem,
  DatePickerFieldElemProps,
} from "./helpers/DatePickerFieldElem";
import { useAppSelector } from "redux/hooks";
import { selectContactLang } from "redux/auth/selectors";
import { UserLocale } from "./helpers/DatePicker/DatePicker";

interface DateFieldProps extends Omit<DatePickerFieldElemProps, "locale"> {}

const DatePickerField = (props: DateFieldProps) => {
  const userLanguage = useAppSelector(selectContactLang);

  return <DatePickerFieldElem {...props} locale={userLanguage as UserLocale} />;
};

export default DatePickerField;
