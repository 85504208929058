import { CasaoneResultDescription } from "api/types";
import { FetchMessageResponse, FetchMessageRequestPayload } from "./types";

const invalidatesTags: CasaoneResultDescription<
  FetchMessageResponse,
  FetchMessageRequestPayload
> = (response: any) => {
  if (response?.status === "success") {
    return ["Event"];
  }
  return [];
};

export default invalidatesTags;
