/* eslint-disable deprecation/deprecation */
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import store from "redux/store";
import { actions as propertyActions } from "../entities/property/store";
import { actions as propertyMapActions } from "../entities/propertyMap/store";
import { actions as propertyModalActions } from "../entities/propertyModal/store";
import { actions as unitActions } from "../entities/unit/store";
import { actions as projectActions } from "../entities/project/store";
import { actions as projectContractActions } from "../entities/projectContract/store";
import { actions as eventActions } from "../entities/event/depreceatedStore";
import { actions as dynamicEventActions } from "../entities/event/dynamicStore";
import { actions as propertyContentActions } from "../entities/propertyContent/store";
import { actions as propertyContentDescriptionActions } from "../entities/propertyContentDescription/store";
import { actions as propertyContentMediaActions } from "../entities/propertyContentMedia/store";
import { actions as propertyContentUrlActions } from "../entities/propertyContentUrl/store";
import { actions as appointmentActions } from "../entities/appointment/store";
import { actions as propertyReportActions } from "../entities/propertyReport/store";
import { actions as propertyReportCommentActions } from "../entities/propertyReportComment/store";
import { actions as addressActions } from "../entities/address/store";
import { actions as subsidiaryActions } from "../entities/subsidiary/store";
import { actions as caAddressActions } from "../entities/caAddress/store";
import { actions as caContractActions } from "../entities/caContract/store";
import { actions as caCompanyCoordActions } from "../entities/caCompanyCoord/store";
import { actions as propertyMatchActions } from "../entities/propertyMatch/store";
import { actions as propertyContactActions } from "../entities/propertyContact/store";
import { actions as dynamicPropertyContactActions } from "../entities/dynamicPropertyContact/store";
import { actions as contactMediasActions } from "../entities/contactMedia/store";
import { actions as eventMediasActions } from "../entities/eventMedia/store";
import { actions as serviceRequestActions } from "../entities/serviceRequest/store";
import { actions as serviceCreditActions } from "../entities/serviceCredit/store";
import { actions as contactSearchprofileActions } from "../entities/contactSearchprofile/store";
import { actions as contactMortgageActions } from "../entities/contactMortgage/store";
import { actions as mediasActions } from "../entities/media/store";
import { actions as mediaChoosersActions } from "../entities/mediaChooser/store";
import { actions as templateActions } from "../entities/template/store";
import { actions as propertyReportViewerActions } from "../entities/propertyReportViewer/store";
import { actions as propertyIntegratedOfferActions } from "../entities/propertyIntegratedOffer/store";
import { actions as contactWithAccesskeyActions } from "../entities/contactWithAccesskey/store";
import { actions as contactSearchprofileWithAccesskeyActions } from "../entities/contactSearchprofileWithAccesskey/store";
import { actions as addressWithAccesskeyActions } from "../entities/addressWithAccesskey/store";
import { actions as placeActions } from "../entities/place/store";
import { actions as userSignatureActions } from "../entities/userSignature/store";
import { actions as searchprofileTemplateActions } from "../entities/searchprofileTemplate/store";
import { actions as cgProviderActions } from "../entities/cgProvider/store";
import { actions as cgPublisherActions } from "../entities/cgPublisher/store";
import { actions as cgProviderPublisherSettingsActions } from "../entities/cgProviderPublisherSettings/store";
import { actions as cgCompanyPublisherSettingsActions } from "../entities/cgCompanyPublisherSettings/store";
import { actions as caProductActions } from "../entities/caProduct/store";
import { actions as propertyPublisherActions } from "../entities/propertyPublisher/store";
import { actions as eventAutoreplyActions } from "../entities/eventAutoreply/store";

import { changeResourcePage } from "redux/app";

import { OrderByItem } from "./queryBuilder";
import { createGtagEvent } from "./gtagHelper";

const actions = {
  properties: propertyActions,
  propertiesMap: propertyMapActions,
  propertiesModal: propertyModalActions,
  units: unitActions,
  projects: projectActions,
  projectContracts: projectContractActions,
  propertyContents: propertyContentActions,
  propertyContentDescriptions: propertyContentDescriptionActions,
  propertyContentMedias: propertyContentMediaActions,
  propertyContentUrls: propertyContentUrlActions,
  appointments: appointmentActions,
  propertyReports: propertyReportActions,
  propertyReportComments: propertyReportCommentActions,
  events: eventActions,
  dynamicEvents: dynamicEventActions,
  addresses: addressActions,
  subsidiaries: subsidiaryActions,
  caAddresses: caAddressActions,
  caContracts: caContractActions,
  caCompanyCoords: caCompanyCoordActions,
  propertyMatches: propertyMatchActions,
  propertyContacts: propertyContactActions,
  dynamicPropertyContacts: dynamicPropertyContactActions,
  contactMedias: contactMediasActions,
  eventMedias: eventMediasActions,
  serviceRequests: serviceRequestActions,
  serviceCredits: serviceCreditActions,
  contactSearchprofiles: contactSearchprofileActions,
  contactMortgages: contactMortgageActions,
  medias: mediasActions,
  mediaChoosers: mediaChoosersActions,
  templates: templateActions,
  propertyReportViewers: propertyReportViewerActions,
  propertyIntegratedOffers: propertyIntegratedOfferActions,
  contactsWithAccesskey: contactWithAccesskeyActions,
  contactSearchprofilesWithAccesskey: contactSearchprofileWithAccesskeyActions,
  addressesWithAccesskey: addressWithAccesskeyActions,
  places: placeActions,
  userSignatures: userSignatureActions,
  searchprofileTemplates: searchprofileTemplateActions,
  cgProviders: cgProviderActions,
  cgPublishers: cgPublisherActions,
  cgProviderPublisherSettings: cgProviderPublisherSettingsActions,
  cgCompanyPublisherSettings: cgCompanyPublisherSettingsActions,
  caProducts: caProductActions,
  propertyPublishers: propertyPublisherActions,
  // eventPhases: eventPhaseActions,
  eventAutoreplies: eventAutoreplyActions,
} as any;

/**
 * @deprecated resourcehelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
 */
const resourceHelper = {
  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  lx(storeNameInput: string, targetStr: string): string {
    const storeName = storeNameInput;
    switch (targetStr) {
      case "fetchItems":
        return `fetch${this.capitalizeFirstLetter(storeName)}`;
      case "getItem":
        return `get${this.capitalizeFirstLetter(
          this.lx(storeName, "depluralize")
        )}`;
      case "hydrateItem":
        return `hydrate${this.capitalizeFirstLetter(
          this.lx(storeName, "depluralize")
        )}`;
      case "updateItem":
        return `update${this.capitalizeFirstLetter(
          this.lx(storeName, "depluralize")
        )}`;
      case "deleteItem":
        return `delete${this.capitalizeFirstLetter(
          this.lx(storeName, "depluralize")
        )}`;
      case "createItem":
        return `create${this.capitalizeFirstLetter(
          this.lx(storeName, "depluralize")
        )}`;
      case "commitQueryForItem":
        return `commitQueryFor${this.capitalizeFirstLetter(
          this.lx(storeName, "depluralize")
        )}`;
      case "commitListMetadataForItem":
        return `commitListMetadataFor${this.capitalizeFirstLetter(
          this.lx(storeName, "depluralize")
        )}`;
      case "depluralize":
        if (storeNameInput === "cgProviderPublisherSettings") {
          return "cgProviderPublisherSettings";
        }
        if (storeNameInput === "cgCompanyPublisherSettings") {
          return "cgCompanyPublisherSettings";
        }
        if (
          storeNameInput === "userSignatures" ||
          storeNameInput === "userSignature"
        ) {
          return "userSignature";
        }
        if (
          storeNameInput === "eventPhases" ||
          storeNameInput === "eventPhase"
        ) {
          return "eventPhase";
        }
        if (
          storeNameInput === "eventAutoreplies" ||
          storeNameInput === "eventAutoreply"
        ) {
          return "eventAutoreply";
        }
        if (
          storeNameInput === "propertyMap" ||
          storeNameInput === "propertiesMap"
        ) {
          return "propertyMap";
        }
        if (
          storeNameInput === "contactsWithAccesskey" ||
          storeNameInput === "contactWithAccesskey"
        ) {
          return "contactWithAccesskey";
        }
        if (
          storeNameInput === "contactSearchprofilesWithAccesskey" ||
          storeNameInput === "contactSearchprofileWithAccesskey"
        ) {
          return "contactSearchprofileWithAccesskey";
        }
        if (
          storeNameInput === "addressesWithAccesskey" ||
          storeNameInput === "addressWithAccesskey"
        ) {
          return "addressWithAccesskey";
        }
        if (
          storeNameInput === "contactMortgage" ||
          storeNameInput === "contactMortgages"
        ) {
          return "contactMortgage";
        }
        if (
          storeNameInput === "propertiesModal" ||
          storeNameInput === "propertyModal"
        ) {
          return "propertyModal";
        }
        if (
          storeNameInput === "contactSearchprofiles" ||
          storeNameInput === "contactSearchprofile"
        ) {
          return "contactSearchprofile";
        }
        if (storeName.endsWith("ies")) {
          return `${storeName.slice(0, -3)}y`;
        }
        if (storeName.endsWith("ges")) {
          return `${storeName.slice(0, -1)}`;
        }
        if (storeName.endsWith("ces")) {
          return `${storeName.slice(0, -2)}e`;
        }
        if (storeName.endsWith("tes")) {
          return `${storeName.slice(0, -2)}e`;
        }
        if (storeName.endsWith("ees")) {
          return `${storeName.slice(0, -1)}`;
        }
        if (storeName.endsWith("es")) {
          return `${storeName.slice(0, -2)}`;
        }
        if (storeName.endsWith("s")) {
          return storeName.slice(0, -1);
        }
        return storeName;
      case "pluralize":
        if (storeNameInput === "cgProviderPublisherSettings") {
          return "cgProviderPublisherSettings";
        }
        if (storeNameInput === "cgCompanyPublisherSettings") {
          return "cgCompanyPublisherSettings";
        }
        if (
          storeNameInput === "eventPhases" ||
          storeNameInput === "eventPhase"
        ) {
          return "eventPhases";
        }
        if (
          storeNameInput === "eventAutoreplies" ||
          storeNameInput === "eventAutoreply"
        ) {
          return "eventAutoreplies";
        }
        if (
          storeNameInput === "propertyMap" ||
          storeNameInput === "propertiesMap"
        ) {
          return "propertiesMap";
        }
        if (
          storeNameInput === "contactsWithAccesskey" ||
          storeNameInput === "contactWithAccesskey"
        ) {
          return "contactsWithAccesskey";
        }
        if (
          storeNameInput === "contactSearchprofilesWithAccesskey" ||
          storeNameInput === "contactSearchprofileWithAccesskey"
        ) {
          return "contactSearchprofilesWithAccesskey";
        }
        if (
          storeNameInput === "addressesWithAccesskey" ||
          storeNameInput === "addressWithAccesskey"
        ) {
          return "addressesWithAccesskey";
        }
        if (
          storeNameInput === "contactSearchprofiles" ||
          storeNameInput === "contactSearchprofile"
        ) {
          return "contactSearchprofiles";
        }
        if (
          storeNameInput === "contactMortgage" ||
          storeNameInput === "contactMortgages"
        ) {
          return "contactMortgages";
        }
        if (
          storeNameInput === "propertiesModal" ||
          storeNameInput === "propertyModal"
        ) {
          return "propertiesModal";
        }
        if (storeName.endsWith("y")) {
          return `${storeName.slice(0, -1)}ies`;
        }
        if (storeName.endsWith("ss")) {
          return `${storeName}es`;
        }
        if (!storeName.endsWith("s")) {
          return `${storeName}s`;
        }
        return storeName;
      default:
        throw Error(`could not lex: ${storeName} to ${targetStr}.`);
    }
  },
  commonFetchList(
    storeName: string,
    pureParams: any,
    commitQueryAction: any,
    fetchItemsAction: any,
    directState: any,
    force: boolean,
    urlParams = {},
    commitListMetadataAction: any = null
  ) {
    const filtrationHasChanged =
      !commitQueryAction ||
      !directState ||
      !directState.lastQuery ||
      !isEqual(directState.lastQuery.pageSize, pureParams.pageSize) ||
      !isEqual(directState.lastQuery.filter, pureParams.filter) ||
      !isEqual(directState.lastQuery.orderBy, pureParams.orderBy) ||
      !isEqual(directState.lastQuery.apiContext, pureParams.apiContext);
    if (
      force ||
      filtrationHasChanged ||
      !isEqual(directState.lastQuery.page, pureParams.page)
    ) {
      // if paged but filtration has change, change to page 1 to avoid over-fetching errors.
      if (filtrationHasChanged && pureParams.page && pureParams.page > 1) {
        store.dispatch(changeResourcePage(storeName, 1));
      }

      if (typeof commitQueryAction === "function") {
        store.dispatch(commitQueryAction(pureParams));
      } else {
        throw Error(
          `commitQueryAction missing: ${storeName}: ${commitQueryAction} (commitQueryFor${this.capitalizeFirstLetter(
            this.lx(storeName, "depluralize")
          )})`
        );
      }
      return new Promise(async (resolve) => {
        const response = await store.dispatch(
          fetchItemsAction(urlParams, {
            ...pureParams,
            page:
              filtrationHasChanged && pureParams.page && pureParams.page > 1
                ? undefined
                : pureParams.page,
          })
        );
        const listMetadata = {
          page: response.res.body.page,
          pageCount: response.res.body.page_count,
          pageSize: response.res.body.page_size,
          totalItems: response.res.body.total_items,
          _links: response.res.body._links,
        };
        if (typeof commitListMetadataAction === "function") {
          store.dispatch(commitListMetadataAction(listMetadata));
        }
        resolve({ items: response.body, listMetadata });
      });
    }
    return new Promise((resolve) => {
      resolve({
        items: directState.items,
        listMetadata: directState.listMetadata,
      });
    });
  },
  grabList(storeNameInput: string) {
    const storeName = this.lx(storeNameInput, "pluralize");
    const state: any = store.getState();
    if (state[storeName] && state[storeName].items) {
      return state[storeName].items;
    }
    return [];
  },
  fetchList(
    storeNameInput: string,
    filter?: any,
    orderBy?: OrderByItem[],
    pageSize?: number,
    force = false,
    apiContext: string | null = null,
    urlParams = {},
    page = 1
  ) {
    const storeName = this.lx(storeNameInput, "pluralize");
    const pureFilter = filter ? cloneDeep(filter) : [];
    const pureOrderBy = orderBy ? cloneDeep(orderBy) : [];
    const pureParams = {
      filter: pureFilter,
      orderBy: pureOrderBy,
      pageSize: pageSize || 20, // Should this be null ?
      apiContext,
      page: page > 1 ? page : undefined,
    };
    const state: any = store.getState();
    if (actions[storeName]) {
      const commitQueryForItem = this.lx(storeName, "commitQueryForItem");
      const commitListMetadataForItem = this.lx(
        storeName,
        "commitListMetadataForItem"
      );
      const fetchItems = this.lx(storeName, "fetchItems");
      return this.commonFetchList(
        storeName,
        pureParams,
        actions[storeName][commitQueryForItem],
        actions[storeName][fetchItems],
        state[storeName],
        force,
        urlParams,
        actions[storeName][commitListMetadataForItem]
      );
    }
    throw Error(`unknown storeName ${storeNameInput}`);
  },
  commonUpdateListQuery(
    storeName: string,
    pureParams: any,
    commitQueryAction: any,
    fetchItemsAction: any,
    directState: any,
    fetchList: any,
    force: boolean
  ) {
    const filter =
      pureParams.filter !== undefined
        ? pureParams.filter
        : directState.lastQuery.filter;
    const orderBy =
      pureParams.orderBy !== undefined
        ? pureParams.orderBy
        : directState.lastQuery.orderBy;
    const pageSize =
      pureParams.pageSize !== undefined
        ? pureParams.pageSize
        : directState.lastQuery.pageSize;
    const page =
      pureParams.page !== undefined
        ? pureParams.page
        : directState.lastQuery.page;
    if (
      force ||
      !directState ||
      !directState.lastQuery ||
      !isEqual(directState.lastQuery.pageSize, pageSize) ||
      !isEqual(directState.lastQuery.filter, filter) ||
      !isEqual(directState.lastQuery.orderBy, orderBy) ||
      !isEqual(directState.lastQuery.page, page)
    ) {
      store.dispatch(
        commitQueryAction({
          filter,
          orderBy,
          pageSize,
          page,
        })
      );
      if (fetchList) {
        this.fetchList(
          storeName,
          filter,
          orderBy,
          pageSize,
          true,
          undefined,
          undefined,
          page
        );
      }
    }
    return new Promise((resolve) => {
      resolve(directState.items);
    });
  },
  updateListQuery(
    storeNameInput: string,
    filter?: any,
    orderBy?: OrderByItem[],
    pageSize?: number,
    fetchList?: boolean,
    force = false,
    page = 1
  ) {
    const storeName = this.lx(storeNameInput, "pluralize");
    const pureFilter = filter !== undefined ? cloneDeep(filter) : undefined;
    const pureOrderBy = orderBy !== undefined ? cloneDeep(orderBy) : undefined;
    const pureParams = {
      filter: pureFilter,
      orderBy: pureOrderBy,
      pageSize,
      page,
    };
    const state: any = store.getState();
    if (actions[storeName]) {
      const commitQueryForItem = this.lx(storeName, "commitQueryForItem");
      const fetchItems = this.lx(storeName, "fetchItems");
      return this.commonUpdateListQuery(
        storeName,
        pureParams,
        actions[storeName][commitQueryForItem],
        actions[storeName][fetchItems],
        state[storeName],
        fetchList,
        force
      );
    }
    throw Error(`unknown storeName ${storeNameInput}`);
  },
  commonReloadList(
    fetchItemsAction: any,
    directState: any,
    storeName: string,
    urlParams = {}
  ) {
    if (directState.lastQuery) {
      return new Promise(async (resolve) => {
        const response = await store.dispatch(
          fetchItemsAction(urlParams, {
            pageSize: directState.lastQuery.pageSize,
            filter: directState.lastQuery.filter,
            orderBy: directState.lastQuery.orderBy,
            apiContext: directState.lastQuery.apiContext,
          })
        );
        const listMetadata = {
          page: response.res.body.page,
          pageCount: response.res.body.page_count,
          pageSize: response.res.body.page_size,
          totalItems: response.res.body.total_items,
          _links: response.res.body._links,
        };
        const commitListMetadataForItem = this.lx(
          storeName,
          "commitListMetadataForItem"
        );
        const commitListMetadataAction =
          actions[storeName][commitListMetadataForItem];
        if (typeof commitListMetadataAction === "function") {
          store.dispatch(commitListMetadataAction(listMetadata));
        }
        resolve(response);
      });
    }
    throw Error(`no lastQuery for: ${storeName}`);
  },
  async reloadList(storeNameInput: string, urlParams = {}) {
    const storeName = this.lx(storeNameInput, "pluralize");
    const state: any = store.getState();
    if (actions[storeName]) {
      const fetchItems = this.lx(storeName, "fetchItems");
      try {
        return await this.commonReloadList(
          actions[storeName][fetchItems],
          state[storeName],
          storeName,
          urlParams
        );
      } catch (error) {
        return new Promise((resolve) => {
          resolve(error);
        });
      }
    }
    throw Error(`unknown storeName ${storeNameInput}`);
  },
  commonGetItem(
    getAction: any,
    hydrateAction: any,
    idOrParams: any,
    directState: any,
    force = false
  ) {
    let id = idOrParams;
    let params = { id };
    if (typeof idOrParams === "object") {
      id = idOrParams.id;
      params = idOrParams;
    }
    if (
      force ||
      !directState.item ||
      (!directState.item.id && !directState.item.username) ||
      (!isEqual(id, directState.item.id) &&
        !isEqual(id, directState.item.username))
    ) {
      return store
        .dispatch(getAction(params))
        .then((res: any) => res.body)
        .then((response: any) => {
          if (hydrateAction) {
            store.dispatch(hydrateAction(response));
          }
          return response;
        });
    }
    return new Promise((resolve) => {
      resolve(directState.item);
    });
  },
  getItem(storeNameInput: string, idOrParams: any, force = false) {
    const storeName = this.lx(storeNameInput, "pluralize");
    const state: any = store.getState();
    if (actions[storeName]) {
      const getItem = this.lx(storeName, "getItem");
      const hydrateItem = this.lx(storeName, "hydrateItem");
      return this.commonGetItem(
        actions[storeName][getItem],
        actions[storeName][hydrateItem],
        idOrParams,
        state[storeName],
        force
      );
    }
    return new Promise((resolve, reject) => {
      reject(`unknown storeName ${storeNameInput}`);
    });
  },
  commonUpdateItem(updateAction: any, data: any) {
    return store.dispatch(updateAction(data));
  },
  updateItem(storeNameInput: string, data: any, refetch = false) {
    const storeName = this.lx(storeNameInput, "pluralize");
    if (actions[storeName]) {
      const updateItem = this.lx(storeName, "updateItem");
      createGtagEvent("Created item", storeName);
      if (refetch) {
        return this.commonUpdateItem(actions[storeName][updateItem], data).then(
          (response: any) => {
            if (response.code === 200) {
              this.getItem(storeName, data, true);
              if (
                (data.doneReason && data.doneReason === "ignore") ||
                (data.status && data.status === "ignore")
              ) {
                const state: any = store.getState();

                if (state[storeName] && state[storeName].lastQuery) {
                  this.fetchList(
                    storeName,
                    state[storeName].lastQuery.filter,
                    state[storeName].lastQuery.orderBy,
                    state[storeName].lastQuery.pageSize,
                    true,
                    undefined,
                    undefined,
                    state[storeName].lastQuery.page
                  );
                } else {
                  this.fetchList(storeName, [], [], 20, true);
                }
              }
            }
            return response;
          }
        );
      }
      return this.commonUpdateItem(actions[storeName][updateItem], data);
    }
    return new Promise((resolve, reject) => {
      reject(`unknown storeName ${storeNameInput}`);
    });
  },
  commonCreateItem(createAction: any, data: any) {
    return store.dispatch(createAction(data));
  },
  createItem(
    storeNameInput: string,
    data: any,
    refetch = false,
    urlParamsForRefetch = {}
  ) {
    const storeName = this.lx(storeNameInput, "pluralize");
    if (actions[storeName]) {
      const createItem = this.lx(storeName, "createItem");
      createGtagEvent("Created item", storeName);
      if (refetch) {
        return this.commonCreateItem(actions[storeName][createItem], data).then(
          (response: any) => {
            const state: any = store.getState();
            if (response.code === 201) {
              if (state[storeName] && state[storeName].lastQuery) {
                this.fetchList(
                  storeName,
                  state[storeName].lastQuery.filter,
                  state[storeName].lastQuery.orderBy,
                  state[storeName].lastQuery.pageSize,
                  true,
                  null,
                  urlParamsForRefetch,
                  state[storeName].lastQuery.page
                );
              } else {
                this.fetchList(
                  storeName,
                  [],
                  [],
                  20,
                  true,
                  null,
                  urlParamsForRefetch
                );
              }
            }
            return response;
          }
        );
      }
      return this.commonCreateItem(actions[storeName][createItem], data);
    }
    return new Promise((resolve, reject) => {
      reject(`unknown storeName ${storeNameInput}`);
    });
  },
  commonDeleteItem(deleteAction: any, data: any) {
    return store.dispatch(deleteAction(data));
  },
  deleteItem(
    storeNameInput: string,
    data: any,
    refetch = false,
    urlParamsForRefetch = {}
  ) {
    const storeName = this.lx(storeNameInput, "pluralize");
    if (actions[storeName]) {
      const deleteItem = this.lx(storeName, "deleteItem");
      if (refetch) {
        return this.commonDeleteItem(actions[storeName][deleteItem], data).then(
          (response: any) => {
            // const state = store.getState();
            if (response.code === 204) {
              this.reloadList(storeName, urlParamsForRefetch);
            }
            return response;
          }
        );
      }
      return this.commonCreateItem(actions[storeName][deleteItem], data);
    }
    return new Promise((resolve, reject) => {
      reject(`unknown storeName ${storeNameInput}`);
    });
  },
};

/**
 * @deprecated resourcehelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
 */
export default resourceHelper;
