import { twMerge } from "tailwind-merge";
import Button from "../../forms/Button";
import * as React from "react";

export type FormGroupMessage = {
  type?: "error" | "success" | "info";
  text?: string | React.ReactNode;
};

export interface FormGroupProps {
  children: React.ReactNode;
  status?: string;
  focused?: boolean;
  filled?: boolean;
  className?: string;
  label?: string | React.ReactNode;
  id?: string;
  required?: boolean;
  nobox?: boolean;
  message?: FormGroupMessage | FormGroupMessage[];
  text?: string[];
  formType?: string;
  disableLabel?: boolean;
  quickActions?: {
    key: string | number;
    label: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }[];
}

const FormGroup = ({
  children,
  status = "",
  focused = false,
  filled = false,
  className = "",
  label = "",
  id = "",
  required = false,
  nobox = false,
  message,
  text,
  formType = "group",
  disableLabel = false,
  quickActions,
}: FormGroupProps) => {
  const formTypeClass = `cs-form-${formType}`;
  const classes = [formTypeClass];
  if (status) {
    classes.push(`${formTypeClass}--${status}`);
  }
  if (focused) {
    classes.push(`${formTypeClass}--focused`);
  }
  if (filled) {
    classes.push(`${formTypeClass}--filled`);
  }
  if (className) {
    classes.push(className);
  }
  if (nobox) {
    classes.push(`${formTypeClass}--nobox`);
  }

  const messageArray: {
    type?: "error" | "success" | "info" | undefined;
    text?: string | React.ReactNode;
  }[] = [];
  if (message) {
    if (Array.isArray(message)) {
      messageArray.push(...message);
    } else {
      messageArray.push(message);
    }
  }

  return (
    <div
      className={twMerge(classes)}
      data-testid={id ? `formGroup-${id}` : undefined}
    >
      {label && !disableLabel ? (
        typeof label === "string" ? (
          <label
            htmlFor={id}
            dangerouslySetInnerHTML={{
              __html: `${label}${required ? "*" : ""}`,
            }}
          />
        ) : (
          <label htmlFor={id}>
            {label}
            {required ? "*" : ""}
          </label>
        )
      ) : null}

      {children}
      {!!messageArray.length && (
        <span>
          {messageArray.map((item, i, arr) => {
            const divider = i < arr.length - 1 && <br />;
            let messageType = "";
            switch (item.type) {
              case "error":
                messageType = "invalid";
                break;
              case "success":
                messageType = "valid";
                break;
              case "info":
                messageType = "info";
                break;
              default:
                messageType = "invalid";
                break;
            }
            if (!item.text) {
              return null;
            }
            return (
              <span className={`${messageType}-feedback`} key={i}>
                {typeof item.text === "string" ? (
                  <span dangerouslySetInnerHTML={{ __html: item.text }} />
                ) : (
                  item.text
                )}
                {divider}
              </span>
            );
          })}
        </span>
      )}
      {text && (
        <span className="cs-form-text tw-text-cs-shade-500">
          {text.map((item, i, arr) => {
            const divider = i < arr.length - 1 && <br />;
            return (
              <span key={i}>
                <span dangerouslySetInnerHTML={{ __html: item }} />
                {divider}
              </span>
            );
          })}
        </span>
      )}
      {quickActions && (
        <div className="tw--ml-2" role="group">
          {quickActions.map((quickAction) => {
            return (
              <Button
                key={quickAction.key}
                role="button"
                tabIndex={-1}
                isLink
                isSmall
                onClick={(e) => quickAction.onClick(e)}
              >
                {quickAction.label}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FormGroup;
