import { casaoneApi } from "api";
import getMessageSettingsTypesQuery from "./endpoints/types/query";
import getCompanyMessageSettingsQuery from "./endpoints/company-get-single/query";
import getUserMessageSettingsQuery from "./endpoints/user-get-single/query";
import removeCompanyMessageSettingsQuery from "./endpoints/company-remove-single/query";
import removeUserMessageSettingsQuery from "./endpoints/user-remove-single/query";
import createMessageSettingsQuery from "./endpoints/create-single/query";
import updateCompanyMessageSettingsQuery from "./endpoints/company-update-single/query";
import updateUserMessageSettingsQuery from "./endpoints/user-update-single/query";
import getSharedSettingsQuery from "./endpoints/get-shared-settings/query";
import type { GetMessageSettingsTypesResponse } from "./endpoints/types/types";
import {
  CasaMessageSettingsResponseShape,
  GetSingleUserMessageSettingsPayload,
} from "./endpoints/user-get-single/types";
import {
  GetSharedSettingsListQueryParams,
  GetSharedSettingsListResponse,
} from "./endpoints/get-shared-settings/types";

const messageSettingsApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    getMessageSettingsTypes: builder.query<
      GetMessageSettingsTypesResponse,
      void
    >({
      query: getMessageSettingsTypesQuery,
      providesTags: ["MessageSettingsTypes"],
    }),
    getCompanyMessageSettings: builder.query<
      CasaMessageSettingsResponseShape,
      void
    >({
      query: getCompanyMessageSettingsQuery,
      providesTags: ["MessageSettings"],
      extraOptions: {
        successOn404: true,
      },
    }),
    getUserMessageSettings: builder.query<
      CasaMessageSettingsResponseShape,
      GetSingleUserMessageSettingsPayload
    >({
      query: getUserMessageSettingsQuery,
      providesTags: ["MessageSettings"],
      extraOptions: {
        successOn404: true,
      },
    }),
    removeCompanyMessageSettings: builder.mutation({
      query: removeCompanyMessageSettingsQuery,
      invalidatesTags: () => ["MessageSettings"],
    }),
    removeUserMessageSettings: builder.mutation({
      query: removeUserMessageSettingsQuery,
      invalidatesTags: () => ["MessageSettings", "User"],
    }),
    createMessageSettings: builder.mutation({
      query: createMessageSettingsQuery,
      invalidatesTags: (result) => {
        if (result) return ["MessageSettings", "User"];
        return [];
      },
    }),
    updateCompanyMessageSettings: builder.mutation({
      query: updateCompanyMessageSettingsQuery,
      invalidatesTags: (result) => {
        if (result) return ["MessageSettings"];
        return [];
      },
    }),
    updateUserMessageSettings: builder.mutation({
      query: updateUserMessageSettingsQuery,
      invalidatesTags: (result) => {
        if (result) return ["MessageSettings", "User"];
        return [];
      },
    }),
    getSharedSettings: builder.query<
      GetSharedSettingsListResponse,
      GetSharedSettingsListQueryParams
    >({
      query: getSharedSettingsQuery,
      providesTags: ["MessageSettings"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMessageSettingsTypesQuery,
  useGetCompanyMessageSettingsQuery,
  useGetUserMessageSettingsQuery,
  useRemoveCompanyMessageSettingsMutation,
  useRemoveUserMessageSettingsMutation,
  useCreateMessageSettingsMutation,
  useUpdateCompanyMessageSettingsMutation,
  useUpdateUserMessageSettingsMutation,
  useGetSharedSettingsQuery,
} = messageSettingsApi;
