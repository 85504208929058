import { useLazyGetUserListQuery } from "api/entities/user";
import { UserShape } from "api/entities/user/types";
import { ContactShape } from "entities/contact/types";
import { FilterItem } from "utilities/queryBuilder";

const PREFER_CACHE_VALUE = true;

const contactIdFilter = (idList: string[]): FilterItem => ({
  where: "and",
  field: "id",
  type: "in",
  alias: "contact",
  values: idList,
});

const userEmailFilter = (emailList: string[]): FilterItem => ({
  where: "and",
  field: "username",
  type: "in",
  values: emailList,
});

export const useFilterFormContactLoader = () => {
  const [trigger] = useLazyGetUserListQuery();

  const loadContactDetails = async ({
    idList,
    byEmail,
  }: {
    idList: string[];
    byEmail: boolean;
  }): Promise<Record<string, ContactShape>> => {
    if (idList.length === 0) {
      return {};
    }

    const data = await trigger(
      {
        query: {
          page: 1,
          pageSize: idList.length,
          filter: [
            {
              type: "innerjoin",
              field: "contact",
              alias: "contact",
            },
            {
              type: "eq",
              field: "contactType",
              value: "user",
              alias: "contact",
            },
            {
              where: "and",
              field: "status",
              value: "ignore",
              type: "neq",
            },
            byEmail
              ? { ...userEmailFilter(idList) }
              : { ...contactIdFilter(idList) },
          ],
          orderBy: [
            {
              type: "field",
              field: "created",
              direction: "desc",
            },
          ],
        },
      },
      PREFER_CACHE_VALUE
    ).unwrap();

    return (
      data?._embedded?.user.reduce(
        (acc: Record<string, ContactShape>, user: UserShape) => {
          const contactDetails = user._embedded?.contact;

          if (contactDetails) {
            const key = byEmail ? user.username : contactDetails.id;
            acc[key] = contactDetails;
          }
          return acc;
        },
        {}
      ) || {}
    );
  };

  return { loadContactDetails };
};
