import { useHistory } from "react-router";

export enum Alerts {
  GENERIC_ERROR = "GENERIC_ERROR",
  MESSAGE_PENDING = "MESSAGE_PENDING",
  MESSAGE_SETTINGS_UPDATED = "MESSAGE_SETTINGS_UPDATED",
  NO_MESSAGE_SETTINGS = "NO_MESSAGE_SETTINGS",
  IMAP_SMTP_CONNECTION_FAILED = "IMAP_SMTP_CONNECTION_FAILED",
  IMAP_SMTP_EMAIL_FOLDER_MISSING = "IMAP_SMTP_EMAIL_FOLDER_MISSING",
  NYLAS_CONNECTION_FAILED = "NYLAS_CONNECTION_FAILED",
  NYLAS_EMAIL_FOLDER_MISSING = "NYLAS_EMAIL_FOLDER_MISSING",
  IMAP_SMTP_SEND_MESSAGE_FAILED = "IMAP_SMTP_SEND_MESSAGE_FAILED",
  NYLAS_SEND_MESSAGE_FAILED = "NYLAS_SEND_MESSAGE_FAILED",
  IMAP_SMTP_PREFIX_NEEDED_DOT = "IMAP_SMTP_PREFIX_NEEDED_DOT",
  IMAP_SMTP_PREFIX_NEEDED_SLASH = "IMAP_SMTP_PREFIX_NEEDED_SLASH",
  IMAP_SMTP_PREFIX_NEEDED_UNKNOWN = "IMAP_SMTP_PREFIX_NEEDED_UNKNOWN",
}

export type Alert = keyof typeof Alerts;

export const isAlert = (key: string): key is Alert => {
  return Object.keys(Alerts).includes(key);
};

export const useAlert = () => {
  const history = useHistory();

  const showAlert = (key: Alert) => {
    const params = new URLSearchParams(history.location.search);
    params.set("alert", key);
    history.push({
      pathname: history.location.pathname,
      search: params.toString(),
    });

    const container = document.querySelector(".app__content");
    container?.scrollTo({ top: 0, behavior: "smooth" });
  };

  return { showAlert };
};
