import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import resourceHelper from "utilities/resource";
import useForm, { RegisterFields } from "hooks/useForm";
import { FieldError, SubmitHandler } from "react-hook-form";
import Field from "@casasoft/styleguide/components/formElements/Field";
import Button from "@casasoft/styleguide/components/forms/Button";
import SwitchButton from "@casasoft/styleguide/components/formElements/SwitchButton";
import { Spinner } from "@casasoft/styleguide/components/helpers-ux";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import AddressTypeAhead from "@casasoft/styleguide/components/forms/AddressTypeAhead";
import AddressPicker from "@casasoft/styleguide/components/forms/AddressPickerMapBox";
import asyncLoading from "react-async-loader";
import Config from "../../config";
import {
  BASE_DATA_PROJECT,
  BASE_DATA_PROPERTY,
  BASE_DATA_UNIT,
  getBaseDataTypeFromPropertyType,
} from "utilities/propertyTypes";
import { ErrorMessage } from "@hookform/error-message";
import resourceGrabber from "utilities/resourceGrabber";
import handleFormModalError, {
  FormModalOnFail,
} from "@casasoft/styleguide/utilities/api-error/handleFormModalError";
import { Countries } from "utilities";
import {
  PROPERTY_TOUR_CREATE_FIELD_ADDRESS,
  PROPERTY_TOUR_CREATE_FIELD_NAME,
  PROPERTY_TOUR_CREATE_FIELD_M_METHOD,
} from "tours/property-onboarding/propertyCreateOnboarding";
import { IaziPropertySettingsControl } from "./features/create-property/IaziPropertySettings/IaziPropertySettingsControl";
import type { IaziCommonDataProperty } from "api/entities/iazi/endpoints/get-common-data/types";
import { useValidationFieldRules } from "./features/create-property/hooks/useValidationFieldRules";
import { useIaziMunicipalityInfoMutation } from "api/entities/iazi";

export interface FormShape {
  locality: string | null;
  postalCode: string | null;
  street: string | null;
  streetNumber: string | null;
  lat: number | null;
  lng: number | null;
  country: string | null;
  region: string | null;
  name: string | null;
  marketingMethod: string | null;
  iaziEnabled: boolean;
  iaziPropertyData: IaziCommonDataProperty | undefined;
  iaziHasPropertyOptions?: boolean;
}

interface PropertyCreateFormProps {
  pinUpdateAddress?: boolean;
  gmap?: any;
  propertyType: string;
  onDone: (body: any) => void;
  onFail: FormModalOnFail;
}

export const IAZI_CREATED_FLAG_PREFIX = "iazi_ai";

const PropertyCreateForm = ({
  pinUpdateAddress,
  gmap,
  propertyType,
  onDone,
  onFail,
}: PropertyCreateFormProps) => {
  const { t } = useTranslation();

  // TODO: remove this unnecessary ugly hack after AddressTypeAhead is refactored. It's only purpose is to make sure the Map renders AFTER the TypeAhaed to prevent a double marker bug
  const [mapIsReady, setMapIsReady] = useState(false);
  const [iaziMunicipalityInfoTrigger] = useIaziMunicipalityInfoMutation();
  useEffect(() => {
    setMapIsReady(true);
  }, []);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    trigger,
    getValues,
  } = useForm<FormShape>({
    mode: "onTouched",
    defaultValues: {
      locality: null,
      postalCode: null,
      street: null,
      streetNumber: null,
      lat: null,
      lng: null,
      country: null,
      region: null,
      name: null,
      marketingMethod: "buy",
      iaziEnabled: false,
      iaziPropertyData: undefined,
      iaziHasPropertyOptions: false,
    },
  });

  const { fieldRules } = useValidationFieldRules({ getValues });

  const grabNameTitle = () => {
    // get the title depending on the propertyType
    let nameTitle = "";
    switch (getBaseDataTypeFromPropertyType(propertyType)) {
      case BASE_DATA_UNIT:
        nameTitle = t("Unit name");
        break;
      case BASE_DATA_PROJECT:
        nameTitle = t("Project name");
        break;
      case BASE_DATA_PROPERTY:
        nameTitle = t("Property name");
        break;
      default:
        throw new Error("unknown property type passed");
    }
    return nameTitle;
  };

  const onSubmit: SubmitHandler<FormShape> = async (data) => {
    const addressDate = {
      country: data.country,
      postalCode: data.postalCode,
      locality: data.locality,
      street: data.street,
      streetNumber: data.streetNumber,
      lat: data.lat,
      lng: data.lng,
    };

    const country = data.country;
    const lat = data.lat;
    const lng = data.lng;
    const locality = data.locality;

    // only allowed languages
    const user = resourceGrabber.grab("casaoneUser", "auth");
    const userLang = resourceGrabber.grab("lang", "auth");
    const companyLang = resourceGrabber.grab("mainLang", "auth");
    const lang = resourceGrabber.grab("hasLang", "auth", userLang)
      ? userLang
      : companyLang;

    const propertyData = {
      address: null,
      propertyType: propertyType,
      salesPerson: user?.username,
      marketingMethod: data.marketingMethod,
      salesMethod: data.marketingMethod === "buy" ? "fixed" : undefined,
      contents: [
        {
          lang,
          name: data.name,
          descriptions: [{}],
        },
      ],
      importId: data.iaziPropertyData?.importId,
      ...data.iaziPropertyData,
    };

    //choose the right store
    let foundStoreName = "";
    switch (propertyData.propertyType) {
      case BASE_DATA_PROPERTY:
        foundStoreName = "property";
        break;
      case BASE_DATA_UNIT:
        foundStoreName = "unit";
        break;
      case BASE_DATA_PROJECT:
        foundStoreName = "project";
        break;
      default:
        throw new Error("propertyType is not supported for this modal");
    }

    try {
      const addressResponse = await resourceHelper
        //first create the address
        .createItem("address", addressDate);

      if (addressResponse.code === 201) {
        propertyData.address = addressResponse.body.id;
        const propertyResponse = await resourceHelper
          //after the address, create the property/unit/project
          .createItem(foundStoreName, propertyData);

        if (propertyResponse.code === 201) {
          const propertyId = propertyResponse.body.id;
          const propertyContentId =
            propertyResponse.body._embedded.contents[0].id;
          //if there is lat and lng, get some description from IAZI
          if (!lat || !lng || country !== "CH") {
            onDone(propertyResponse.body);
            return;
          }

          try {
            const iaziResponse = await iaziMunicipalityInfoTrigger({
              countryCode: country,
              latitude: lat,
              longitude: lng,
              lang,
              property: propertyId,
            }).unwrap();

            const descriptions = [];
            if (iaziResponse?.municipalityDescription?.[0]?.description) {
              const description =
                iaziResponse.municipalityDescription[0].description;
              descriptions.push({
                html: description,
                text: description,
                rank: 0,
                title: locality,
                type: "macro-locality",
              });
            }
            // if there are some new descriptions, update the propertyContent
            if (descriptions.length) {
              const updatePropertyData = {
                id: propertyResponse.body.id,
                contents: [
                  {
                    id: propertyContentId,
                    lang,
                    name: propertyData.contents[0].name,
                    descriptions: descriptions,
                  },
                ],
              };

              const updateResponse = await resourceHelper.updateItem(
                foundStoreName,
                updatePropertyData
              );

              if (updateResponse.code === 200) {
                onDone(updateResponse.body);
                return;
              }
            }

            onDone(propertyResponse.body);
          } catch (error) {
            handleFormModalError(error, onFail);
          }
        }
      }
    } catch (error) {
      handleFormModalError(error, onFail);
    }
  };

  return (
    <RegisterFields
      control={control}
      fields={fieldRules}
      render={(fieldsRenderer, chainFields) => {
        return (
          <form
            className="spinner-fixture"
            onSubmit={handleSubmit(onSubmit)}
            style={isSubmitting ? { opacity: 0.5, pointerEvents: "none" } : {}}
            noValidate
          >
            {isSubmitting && <Spinner />}
            {chainFields(
              [
                "country",
                "locality",
                "postalCode",
                "lat",
                "lng",
                "street",
                "streetNumber",
                "region",
              ],
              (formValues, formValueChangeHandlers) => {
                return (
                  <div data-boarding={PROPERTY_TOUR_CREATE_FIELD_ADDRESS}>
                    <AddressTypeAhead
                      required
                      nobox
                      id="locality"
                      label={t("Address")}
                      placeholder={t("Street, locality")}
                      subvalues={{
                        lat: formValues.lat,
                        lng: formValues.lng,
                        street: formValues.street,
                        street_number: formValues.streetNumber,
                        locality: formValues.locality,
                        postal_code: formValues.postalCode,
                        country: formValues.country,
                        region: formValues.region,
                      }}
                      gmap={gmap}
                      onSubvaluesChange={(address: any) => {
                        formValueChangeHandlers.locality(
                          address.locality || null
                        );
                        formValueChangeHandlers.postalCode(
                          address.postal_code || null
                        );
                        formValueChangeHandlers.street(address.street || null);
                        formValueChangeHandlers.streetNumber(
                          address.street_number || null
                        );
                        formValueChangeHandlers.country(
                          address.country || null
                        );
                        formValueChangeHandlers.region(address.region || null);
                        formValueChangeHandlers.lat(address.lat || null);
                        formValueChangeHandlers.lng(address.lng || null);
                        trigger([
                          "locality",
                          "postalCode",
                          "street",
                          "streetNumber",
                          "country",
                          "region",
                          "lat",
                          "lng",
                        ]);
                      }}
                      countryDropdownOptions={Countries.getCountries()}
                      autocomplete="false"
                      translate={(string: string) => {
                        switch (string) {
                          case "Enter address manual":
                            return t("addressTypeAhead.enterAddressManual");
                          case "Street":
                            return t("addressTypeAhead.street");
                          case "Street Number":
                            return t("addressTypeAhead.streetNumber");
                          case "Postal Code":
                            return t("addressTypeAhead.postalCode");
                          case "Locality":
                            return t("addressTypeAhead.locality");
                          case "Post Office Box Number":
                            return t("addressTypeAhead.postOfficeBoxNumber");
                          case "Country":
                            return t("addressTypeAhead.country");
                          case "Automatic address lookup":
                            return t(
                              "addressTypeAhead.Automatic address lookup"
                            );
                          case "Active":
                            return t("Active");
                          default:
                            return string;
                        }
                      }}
                    />
                    {mapIsReady && formValues.lat && (
                      <AddressPicker
                        lat={formValues.lat}
                        lng={formValues.lng}
                        gmap={gmap}
                        zoom={16}
                        mapStyle="street"
                        onChange={(address: any) => {
                          formValueChangeHandlers.lat(address.lat);
                          formValueChangeHandlers.lng(address.lng);
                          if (pinUpdateAddress) {
                            formValueChangeHandlers.locality(address.locality);
                            formValueChangeHandlers.postalCode(
                              address.postal_code
                            );
                            formValueChangeHandlers.street(address.street);
                            formValueChangeHandlers.streetNumber(
                              address.street_number
                            );

                            formValueChangeHandlers.country(address.country);
                            formValueChangeHandlers.region(address.region);
                            trigger([
                              "lat",
                              "lng",
                              "locality",
                              "postalCode",
                              "street",
                              "streetNumber",
                              "country",
                              "region",
                            ]);
                          }
                        }}
                        countryDropdownOptions={Countries.getCountries()}
                        autocomplete="false"
                        pinUpdateAddress={pinUpdateAddress}
                      />
                    )}
                  </div>
                );
              }
            )}
            {fieldsRenderer("name", (formValue, onFormValueChange) => (
              <Field
                nobox
                data-boarding={PROPERTY_TOUR_CREATE_FIELD_NAME}
                type="text"
                required
                label={grabNameTitle()}
                value={formValue || undefined}
                onChange={(value: string) => {
                  onFormValueChange(value || null);
                }}
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="name" />,
                }}
              />
            ))}
            {fieldsRenderer(
              "marketingMethod",
              (formValue, onFormValueChange) => (
                <SwitchButton
                  data-boarding={PROPERTY_TOUR_CREATE_FIELD_M_METHOD}
                  label={t("Marketing Method")}
                  required
                  value={formValue || undefined}
                  onChange={(value) => {
                    onFormValueChange(value || null);
                  }}
                  message={{
                    type: "error",
                    text: (
                      <ErrorMessage errors={errors} name="marketingMethod" />
                    ),
                  }}
                  buttons={[
                    {
                      label: t("Buy"),
                      value: "buy",
                      id: "buy",
                    },
                    {
                      label: t("Rent"),
                      value: "rent",
                      id: "rent",
                    },
                  ]}
                />
              )
            )}
            {chainFields(
              ["iaziEnabled", "iaziPropertyData", "iaziHasPropertyOptions"],
              (formValues, onFormValueChanges) => (
                <IaziPropertySettingsControl
                  isEnabled={formValues.iaziEnabled}
                  selectedProperty={formValues.iaziPropertyData}
                  onEnabledToggle={onFormValueChanges.iaziEnabled}
                  onSelectProperty={onFormValueChanges.iaziPropertyData}
                  onDataFetch={onFormValueChanges.iaziHasPropertyOptions}
                  propertyType={propertyType}
                  address={{
                    lat: getValues("lat") || undefined,
                    lon: getValues("lng") || undefined,
                  }}
                  errors={errors as FieldError}
                />
              )
            )}
            <ModalFooter>
              <Button isPrimary type="submit" buttonValue={t("Create")} />
            </ModalFooter>
          </form>
        );
      }}
    />
  );
};

const compAsyncLoaded = asyncLoading(() => {
  const googleMapsApiKey = Config.googleMapsApiKey;
  const gmap = {
    globalPath: "google.maps",
    url: `${Config.googleMapsApiUrl}/maps/api/js?key=${googleMapsApiKey}&libraries=places`,
    jsonp: true,
  };
  return { gmap };
})(PropertyCreateForm);
export default compAsyncLoaded as typeof PropertyCreateForm;
