import { createQuery } from "api/createEndpoint";
import { MESSAGE_SETTINGS_API_ROOT } from "../constants";

const query = createQuery<void>(() => {
  return {
    url: `${MESSAGE_SETTINGS_API_ROOT}/types`,
    method: "GET",
  };
});

export default query;
