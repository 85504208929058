import { EventShape } from "entities/event/types";
import csMoment from "utilities/csMoment";
import { getAttendeeData } from "../../../../event/helpers/attendees";

const isPublicEvent = (event: EventShape): boolean => !event.private;

const getAuthor = (event: EventShape): string => {
  const { sender } = getAttendeeData(event?._embedded?.attendees);

  if (
    typeof event.transcript === "object" &&
    event.transcript?.smtp?.fromName
  ) {
    return event.transcript?.smtp?.fromName;
  }

  const displayName = sender?._embedded?.contact?.displayName;

  if (displayName) {
    return displayName;
  }
  return "?";
};

const getEmail = (event: EventShape): string => {
  const { sender } = getAttendeeData(event?._embedded?.attendees);

  if (
    typeof event.transcript === "object" &&
    event.transcript?.smtp?.fromEmail
  ) {
    return event.transcript?.smtp?.fromEmail;
  }

  const email = sender?._embedded?.contact?.email;
  if (email) {
    return email;
  }
  return "?";
};

const getTime = (event: EventShape): string =>
  event.start ? csMoment(event.start.date).format("MMMM Do YYYY, HH:mm") : "?";

const createEventHeader = (event: EventShape): string => {
  const name = getAuthor(event);
  const email = getEmail(event);
  const time = getTime(event);

  return `<blockquote type="cite">
    <hr />
    <p>
      ${event.type === "note" ? "Notiz: " : ""}
      ${time}, ${name} <${email}>:
    </p>
    <hr />
    ${event.html}
  </blockquote>`;
};

export const EMPTY_BODY_HTML = "<p><br /></p>";

export const getMessageHistoryHtml = (
  messageEvents: EventShape[] = []
): string | undefined => {
  return messageEvents.length
    ? [...messageEvents.filter(isPublicEvent).map(createEventHeader)].join("")
    : undefined;
};
