export const isValidDateString = (dateString?: string) => {
  if (!dateString) {
    return undefined;
  }
  const date = new Date(dateString);

  return isNaN(date.getTime()) ? undefined : date;
};

/**
 * Compares the day section of the date to check if the date is in the past
 * @param startDate Date to check if it is in the past
 * @param includeToday If true, the function will return true if the date is today
 * @returns true if the day of the provided date is not today
 */
export const isDateInThePast = (
  startDate?: Date | string,
  includeToday = false
) => {
  if (!startDate) {
    return false;
  }

  const date = new Date(startDate);
  const now = new Date();

  if (includeToday) {
    return date.setHours(0, 0, 0, 0) < now.setHours(23, 59, 59, 999);
  }

  return date.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0);
};
