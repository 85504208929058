import { MessageSyncErrorAlert } from "components/message/features/message-list/MessageSyncErrorAlert";
import { MessagePendingAlert } from "components/message/MessagePendingAlert";
import { MessageSettingsUpdatedAlert } from "components/message/MessageSettingsUpdatedAlert";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Alerts, isAlert } from "../helpers-ux/hooks/useAlert";
import { AlertProps } from "../helpers-ux/Alert";
import { history } from "config/history";

export const getAlert = (
  alert: string,
  t: Function,
  color: AlertProps["color"] = "warning"
) => {
  switch (alert) {
    case Alerts.MESSAGE_PENDING:
      return <MessagePendingAlert />;
    case Alerts.MESSAGE_SETTINGS_UPDATED:
      return <MessageSettingsUpdatedAlert />;
    case Alerts.NO_MESSAGE_SETTINGS:
    case Alerts.IMAP_SMTP_CONNECTION_FAILED:
      return (
        <MessageSyncErrorAlert
          text={t(
            'Unable to send/receive message. Check and update your mail server settings under "My Profile" and/or "Company Profile" in your CASAONE settings.'
          )}
          color={color}
        />
      );
    case Alerts.NYLAS_CONNECTION_FAILED:
      return (
        <MessageSyncErrorAlert
          text={t(
            'Unable to send/receive message. Check and update your mail server settings under "My Profile" and/or "Company Profile" in your CASAONE settings. Your email login details may have changed.'
          )}
          color={color}
        />
      );
    case Alerts.IMAP_SMTP_EMAIL_FOLDER_MISSING:
      return (
        <MessageSyncErrorAlert
          text={t("CASAONE folder setting has been updated. Please try again.")}
          color={color}
        />
      );
    case Alerts.NYLAS_EMAIL_FOLDER_MISSING:
      return (
        <MessageSyncErrorAlert
          text={t(
            'CASAONE folder is missing. Please verify within your email account if it’s still present or try to reauthenticate your mail server settings under "My Profile" and/or "Company Profile" in your CASAONE settings.'
          )}
          color={color}
        />
      );
    case Alerts.IMAP_SMTP_SEND_MESSAGE_FAILED:
      return (
        <MessageSyncErrorAlert
          text={t(
            "Unable to send/receive message. There seems to be a technical problem with your e-mail provider. Please contact your providers support if the issue persists."
          )}
          color={color}
        />
      );
    case Alerts.NYLAS_SEND_MESSAGE_FAILED:
      return (
        <MessageSyncErrorAlert
          text={t(
            "Unable to send/receive message. A technical problem cannot be ruled out. Please check the functionality of your e-mail provider. If the issue persists, contact Support."
          )}
          color={color}
        />
      );
    case Alerts.IMAP_SMTP_PREFIX_NEEDED_DOT:
    case Alerts.IMAP_SMTP_PREFIX_NEEDED_SLASH:
      return (
        <MessageSyncErrorAlert
          component={
            <div>
              {t("IMAP_SMTP_PREFIX_NEEDED_SLASH_DOT_1")}{" "}
              <span
                className="tw-underline tw-cursor-pointer"
                onClick={() => history.push("/admin/profile")}
              >
                {t("IMAP_SMTP_PREFIX_NEEDED_SLASH_DOT_2")}
              </span>{" "}
              {t("IMAP_SMTP_PREFIX_NEEDED_SLASH_DOT_3", {
                prefix:
                  alert === Alerts.IMAP_SMTP_PREFIX_NEEDED_DOT
                    ? "INBOX."
                    : "INBOX\\",
              })}
            </div>
          }
          color={color}
        />
      );
    case Alerts.IMAP_SMTP_PREFIX_NEEDED_UNKNOWN:
      return (
        <MessageSyncErrorAlert
          component={
            <div>
              {t("IMAP_SMTP_PREFIX_NEEDED_UNKNOWN_1")}{" "}
              <span
                className="tw-underline tw-cursor-pointer"
                onClick={() => window.open("mailto:support@casasoft.ch")}
              >
                {t("IMAP_SMTP_PREFIX_NEEDED_UNKNOWN_2")}
              </span>{" "}
              {t("IMAP_SMTP_PREFIX_NEEDED_UNKNOWN_3")}
            </div>
          }
          color={color}
        />
      );
    default:
      return (
        <MessageSyncErrorAlert color="danger" text={t("Unknown alert code")} />
      );
  }
};

export const MainAlert = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const alert = params.get("alert");

  if (!alert || !isAlert(alert)) return null;

  return getAlert(alert, t);
};
