import { CasaoneApiTagTypes } from "api/types";
import { startAppListening } from "redux/listenerMiddleware";
import { TagDescription } from "@reduxjs/toolkit/dist/query";
import { casaoneApi } from "api";
import { camelCase } from "lodash";

function getTagTypesForEntity(
  /** represents the "name" of an old resourceHelper entity */
  entityName: string,
  context: any
): TagDescription<CasaoneApiTagTypes>[] | void {
  switch (camelCase(entityName)) {
    case "birthdaysContact":
    case "dynamicContact":
    case "employeeContact":
    case "mainContact":
    case "matchingsContact":
      return [
        {
          type: "Contact",
          id: context?.id,
        },
      ];

    case "companyMailUser":
    case "dynamicUser":
    case "mainProfileUser":
    case "mainUser":
      // skip user lastRoute which happens on every page change
      if (context?.lastRoute && Object.keys(context).length <= 3) {
        return;
      }

      return [
        {
          type: "User",
          id: context?.username || context?.id,
        },
      ];

    case "event":
    case "dynamicEvent":
    case "mainMessage":
    case "mainReminder":
    case "mainTask":
    case "notification":
    case "quickAlert":
    case "quickMessage":
    case "quickReminder":
    case "quickTask":
      const phaseTagTypes: { type: "Phase"; id?: string }[] = [];

      if (context?.eventPhase?.phase) {
        phaseTagTypes.push({
          type: "Phase",
          id: "RELATIONSHIP",
        });
      }

      return [
        ...phaseTagTypes,
        {
          type: "Event",
          id: context?.id,
        },
      ];

    case "dynamicProperty":
    case "project":
    case "property":
    case "singlePublication":
    case "projectPublication":
    case "unit":
      return [
        {
          type: "Property",
          id: context?.id,
        },
      ];
  }
}

const mutations = "(UPDATE|CREATE|DELETE)";

export function subscribeLegacyResourceHelperMiddleware() {
  startAppListening({
    predicate: (action) => {
      // regex to match all mutation actions
      const regex = new RegExp(`^@@resource/.*/${mutations}$`, "g");

      if (action.status === "resolved" && action.type.match(regex)) {
        return true;
      }
      return false;
    },
    effect: (action, { dispatch }) => {
      // remove the @@resource/ and /${mutations} from the action type
      const actionTypeNoResource = action.type.replace(/^@@resource\//g, "");
      const mutationSuffixReg = new RegExp(`/${mutations}$`, "g");
      const actionType = actionTypeNoResource.replace(mutationSuffixReg, "");

      const invalidatedTags = getTagTypesForEntity(actionType, action.context);
      if (invalidatedTags) {
        dispatch(casaoneApi.util.invalidateTags(invalidatedTags));
      }
    },
  });
}
